import { Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'

const LogoAppbar = () => {
  return (
    <Flex gap={5} my={3} alignItems={'center'}>
      <Image src='images\appbar-image.png' alt='Mr Champagne'
        h={'35px'}
      />
      <Text fontSize={'1.05em'} fontWeight="bold">Mr. Champagne Barberia</Text>
      {/* <Text fontSize={'1.05em'} fontWeight="bold">AqualimpSoft</Text> */}
    </Flex>
  )
}

export default LogoAppbar