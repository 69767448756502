import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from '../firebase/firebase'

let collectionRegistroDiarioRef = collection(db, 'registroDiario')


export const getAllRegistrosByBarbero = async (nombreBarbero, fechaInicio, fechaFin) => {
  const queryRef = query(collectionRegistroDiarioRef,
    where('empleado', '==', nombreBarbero),
    where('fecha', '>=', fechaInicio),
    where('fecha', '<=', fechaFin))
  const resultRef = await getDocs(queryRef)
  const list = []
  resultRef.docs.forEach((doc) => {
    const data = {
      id: doc.id,
      ...doc.data()
    }
    list.push(data)
  })
  return list
}

export const getAllRegistrosMesActual = async ( fechaInicio, fechaFin) => {
  const queryRef = query(collectionRegistroDiarioRef,
    where('fecha', '>=', fechaInicio),
    where('fecha', '<=', fechaFin))
  const resultRef = await getDocs(queryRef)
  const list = []
  resultRef.docs.forEach((doc) => {
    const data = {
      id: doc.id,
      ...doc.data()
    }
    list.push(data)
  })
  return list
}