import React, { useState, useEffect } from 'react'
import { Alert, AlertIcon, Box, Flex, useDisclosure, SimpleGrid } from '@chakra-ui/react'
// import KPI from '../components/KPI'
// import { GiMoneyStack } from 'react-icons/gi'
import EmployeeItem from '../components/EmployeeItem'
// import { empleados } from '../dataTest'
import ModalDialog from '../components/ModalDialog'
import DetalleRegistroIngresos from '../components/DetalleRegistroIngresos'
import FormAddIngresoDiario from '../containers/FormAddIngresoDiario'
import { FormProvider, useForm } from 'react-hook-form'
import FormChangeEmployee from '../containers/FormChangeEmployee'
import AlertsDialog from '../components/AlertsDialog'
import { ShowToast } from '../components/Toast'
import moment from 'moment/moment'
import { deleteDetalleFromRegistro, deleteRegistro, getEmpleadosAsync, getRegistroByFecha, saveRegistroDiario } from '../services/registroDiarioServices'
import { v1 as uuidv1 } from 'uuid'
// import { TfiCreditCard } from 'react-icons/tfi'
// import { SiVisa } from 'react-icons/si'
// import { GiCash } from 'react-icons/gi'
// import { currencyFormat } from '../utils/functions'

const RegistroDiario = () => {

  const [listEmpleados, setListEmpleados] = useState([])
  const [employeeSelected, setEmployeeSelected] = useState({})
  const [itemSelected, setItemSelected] = useState({})
  const [newEmployeeToChange, setNewEmployeeToChange] = useState({})
  const [listRegistroDiario, setListRegistroDiario] = useState([])
  const { isOpen: isOpenDetalleRegistro, onOpen: onOpenDetalleRegistro, onClose: onCloseDetalleRegistro } = useDisclosure()
  const { isOpen: isOpenEditRegistro, onOpen: onOpenEditRegistro, onClose: onCloseEditRegistro } = useDisclosure()
  const { isOpen: isOpenAlertDeleteRegistro, onOpen: onOpenAlertDeleteRegistro, onClose: onCloseAlertDeleteRegistro } = useDisclosure()
  const { isOpen: isOpenChangeEmployee, onOpen: onOpenChangeEmployee, onClose: onCloseChangeEmployee } = useDisclosure()
  const { isOpen: isOpenAlertChangeEmployee, onOpen: onOpenAlertChangeEmployee, onClose: onCloseAlertChangeEmployee } = useDisclosure()
  const formMethodsAddNewRegistro = useForm()
  const formMethodsEditRegistro = useForm()
  const formMethodsChangeEmployee = useForm()

  //Abrir el detalle de cada empleado
  const handleOpenDetalleRegistro = (employeeItem) => {
    setEmployeeSelected(employeeItem)
    onOpenDetalleRegistro()
  }
  //save Ingresos
  const handleSaveIngresosToRegistro = async (ingreso) => {
    try {

      const accion = !ingreso.id ? 'create' : 'update'
      let newIngreso
      if (accion === 'create') {
        newIngreso =
        {
          ...ingreso,
          cliente: ingreso.cliente.toUpperCase(),
          fechaIngreso: moment().format('YYYY-MM-DD'),
          fechaLog: moment().format('YYYY-MM-DD HH:mm'),
        }
      } else {
        newIngreso =
        {
          ...ingreso,
          cliente: ingreso.cliente.toUpperCase(),
          empleado: employeeSelected.empleado,
          fechaIngreso: moment().format('YYYY-MM-DD'),
          fechaLog: moment().format('YYYY-MM-DD HH:mm')
        }
      }
      //console.log(accion, ' => ', newIngreso)
      //console.log(listRegistroDiario)
      const employeeToAddIngreso = listRegistroDiario.find(registro => registro.empleado === newIngreso.empleado)
      //console.log(employeeToAddIngreso)
      let newRegistro
      if (employeeToAddIngreso) {
        //console.log('entro aqui')
        newRegistro =
        {
          ...employeeToAddIngreso,
          detalle: accion === 'create' ?
            employeeToAddIngreso.detalle.concat({ ...newIngreso, id: uuidv1() })
            :
            employeeToAddIngreso.detalle.map(item => item.id === newIngreso.id ? newIngreso : item)
        }
        //console.log('registro listo para guardarse: ', newRegistro)
        let idRegistro = await saveRegistroDiario(newRegistro)
        setListRegistroDiario(prev => prev.map(registro => registro.id === idRegistro ? newRegistro : registro))
        //console.log('registro guardado: ', listRegistroDiario)
        //console.log('newRegistro =>', newRegistro)
        if (employeeSelected)
          setEmployeeSelected(newRegistro)

      } else {
        newRegistro = {
          empleado: ingreso.empleado,
          cliente: ingreso.cliente.toUpperCase(),
          fecha: moment().format('YYYY-MM-DD'),
          fechaLog: moment().format('YYYY-MM-DD HH:mm'),
          detalle: [{ ...newIngreso, id: uuidv1() }],
          idCuadreCaja: null
        }
        let idRegistro = await saveRegistroDiario(newRegistro)
        setListRegistroDiario(prev => prev.concat({ ...newRegistro, id: idRegistro }))
      }
      if (isOpenEditRegistro)
        onCloseEditRegistro()

      ShowToast('Registro guardado', '', 'success')
    } catch (error) {
      //console.error(error)
      ShowToast('Error', error, 'error')
    }
  }
  //Abrir el item para editarlo
  const handleEditRegistro = async (employeeItem) => {
    formMethodsEditRegistro.reset()
    formMethodsEditRegistro.setValue('id', employeeItem.id)
    formMethodsEditRegistro.setValue('cliente', employeeItem.cliente)
    formMethodsEditRegistro.setValue('valorPagado', employeeItem.valorPagado)
    formMethodsEditRegistro.setValue('metodoPago', employeeItem.metodoPago)
    //formMethodsEditRegistro.setValue('fechaIngreso', new Date(employeeItem.fechaIngreso))
    onOpenEditRegistro()
    //console.log('voy a editar el empleado ', employeeItem)
  }
  //Abrir el item a transferir
  const handleOpenChangeEmployee = (item) => {
    onOpenChangeEmployee()
    setItemSelected(item)
  }
  //Abrir modal de confirmar el cambio
  const handleOpenConfirmChangeEmployee = (newEmployee) => {
    onOpenAlertChangeEmployee()
    setNewEmployeeToChange(newEmployee)
  }
  //Confirmar el cambio
  const handleConfirmChangeEmployee = () => {
    onCloseAlertChangeEmployee()
    handleChangeEmployee(newEmployeeToChange)
  }
  //Metodo para transferir cliente
  const handleChangeEmployee = async (item) => {
    try {
      // console.log('newEmpleoyee', item)
      // console.log('item selected', itemSelected)
      //Busca el empleado anterior y actualizar su detalle, eliminando el cliente a transferir
      const oldEmployee = listRegistroDiario.find(item => item.empleado === itemSelected.empleado)
      //console.log(oldEmployee)
      const newOldEmployee = {
        ...oldEmployee,
        detalle: oldEmployee.detalle.filter(item => item.id !== itemSelected.id)
      }
      //console.log('newOldEmployee', newOldEmployee)
      let idOldEmployee
      let newListRegistro
      if (newOldEmployee.detalle.length === 0) {
        await deleteRegistro(newOldEmployee.id)
        newListRegistro = listRegistroDiario.filter(registro => registro.id !== newOldEmployee.id)
      } else {
        idOldEmployee = await saveRegistroDiario(newOldEmployee)
        newListRegistro = listRegistroDiario.map(registro => registro.id === idOldEmployee ? newOldEmployee : registro)
      }
      setListRegistroDiario(newListRegistro)
      //fin
      //Buscar el empleado nuevo y añadir el cliente
      //console.log(newListRegistro)
      const employeeToTransfer = listRegistroDiario.find(registro => registro.empleado === item.empleadoNuevo)
      if (employeeToTransfer) {
        //console.log('entro aqui')
        const newEmployee = {
          ...employeeToTransfer,
          detalle: employeeToTransfer.detalle.concat({ ...itemSelected, empleado: item.empleadoNuevo })
        }
        const idNewEmployee = await saveRegistroDiario(newEmployee)
        setListRegistroDiario(prev => prev.map(registro => registro.id === idNewEmployee
          ? newEmployee
          : registro))
      } else {
        const newRegistro = {
          empleado: item.empleadoNuevo,
          fecha: moment().format('YYYY-MM-DD'),
          fechaLog: moment().format('YYYY-MM-DD HH:mm'),
          idCuadreCaja: null,
          detalle: [{ ...itemSelected, empleado: item.empleadoNuevo }]
        }
        let idRegistro = await saveRegistroDiario(newRegistro)
        setListRegistroDiario(prev => prev.concat({ ...newRegistro, id: idRegistro }))
      }
      setItemSelected({})
      setNewEmployeeToChange({})
      onCloseChangeEmployee()
      onCloseDetalleRegistro()
      ShowToast('Transferencia Exitosa', '', 'success')
      // console.log('itemSelected', itemSelected)
      // console.log('newEmployeeToChange', newEmployeeToChange)
    } catch (error) {
      //console.error(error)
      ShowToast('Error', error, 'success')
    }
  }

  // Abrir cuadro de confirmacion de eliminacion
  const handleOpenAlertDelete = (itemToDelete) => {
    onOpenAlertDeleteRegistro()
    setItemSelected(itemToDelete)
  }

  // Confirmar eliminacion
  const handleConfirmDelete = async () => {

    const newDetalle = employeeSelected.detalle.filter(registro => registro.id !== itemSelected.id)
    const employeeUpdated = {
      ...employeeSelected,
      detalle: newDetalle
    }

    await deleteDetalleFromRegistro(employeeUpdated)

    setItemSelected({})

    if (employeeUpdated.detalle.length) {
      setEmployeeSelected(employeeUpdated)
      setListRegistroDiario(prev => prev.map(registro => registro.id !== employeeUpdated.id ? registro : employeeUpdated))
      onCloseAlertDeleteRegistro()

    } else {
      await deleteRegistro(employeeUpdated.id)
      setListRegistroDiario(prev => prev.filter(registro => registro.id !== employeeUpdated.id))
      setEmployeeSelected({})
      onCloseAlertDeleteRegistro()
      onCloseDetalleRegistro()
    }

    ShowToast('Registro Eliminado', '', 'success')
  }

  // Metodo para obtener los registros x fecha
  const getRegistroDeHoy = async (fecha) => {
    const data = await getRegistroByFecha(fecha)
    if (data.length !== 0) {
      setListRegistroDiario(data)
    }
  }

  // Metodo para cargar los empleados/barberos
  const getListEmpleados = async () => {
    const result = await getEmpleadosAsync()
    if (result.length !== 0) {
      setListEmpleados(result.data().empleados)
    }
  }

  //Efecto para cargar los ingresos del dia
  useEffect(() => {
    async function obtenerDatos() {
      await getRegistroDeHoy(moment().format('YYYY-MM-DD'))
      await getListEmpleados()
    }
    obtenerDatos()
  }, [])
  return (
    <Flex w={'100%'}>
      <Box className='jmz-paper' height={['100%', '100vh']} >
        <div className='jmz-card-header'>
          <div className='jmz-card-title'>
            Registro de Ingresos Diarios
          </div>
        </div>
        <Flex flexDir={'column'} gap={5} m={6}>
          <Flex gap={5} flexDir={['column', 'row']} w={'100%'}>
            <Box w={['100%', '70%', '50%', '50%', '25%']} bg={'white'} className={'jmz-outline'} borderRadius={10} p={7} mb={5}>
              <FormProvider {...formMethodsAddNewRegistro}>
                <FormAddIngresoDiario
                  onSubmit={handleSaveIngresosToRegistro}
                  initData={listEmpleados}
                  showEmpleadoField
                />
              </FormProvider>
            </Box>
            <Box w={['100%', '80%']}  >
              {listRegistroDiario.length ? (
                <SimpleGrid minChildWidth={{ base: '250px', md: '350px' }} spacing='20px'>
                  {/* <Flex flexDir={['column', 'column', 'column','column','column', 'row']} gap={3} minWidth={'200px'} w={'100%'}> */}
                  {listRegistroDiario.map((item, index) => (
                    <EmployeeItem key={index} item={item} onClick={handleOpenDetalleRegistro} />
                  ))}
                  {/* </Flex> */}
                </SimpleGrid>
              )
                :
                (<Alert status='info' rounded={'10px'}>
                  <AlertIcon />
                  Aun no hay registros
                </Alert>)
              }

            </Box>
          </Flex>
          <ModalDialog
            textTitleModal={'Detalle Registro'}
            isOpen={isOpenDetalleRegistro}
            onClose={onCloseDetalleRegistro}
            modalSize={'md'}
          >
            <DetalleRegistroIngresos
              employeeItem={employeeSelected}
              handleEditRegistro={handleEditRegistro}
              handleChangeEmployee={handleOpenChangeEmployee}
              handleDeleteRegistro={handleOpenAlertDelete}
            />
          </ModalDialog>
          <AlertsDialog
            titulo={'Eliminar Ingreso'}
            isOpen={isOpenAlertDeleteRegistro}
            onClose={onCloseAlertDeleteRegistro}
            handleEvent={handleConfirmDelete}
            showCancelButton
            colorOkBtn='red'
          >
            ¿Desea elminar este ingreso?
          </AlertsDialog>
          <ModalDialog
            textTitleModal={'Editar Registro'}
            isOpen={isOpenEditRegistro}
            onClose={onCloseEditRegistro}
            modalSize={'md'}
          >
            <FormProvider {...formMethodsEditRegistro}>
              <FormAddIngresoDiario
                onSubmit={handleSaveIngresosToRegistro}
                initData={listEmpleados}
              />
            </FormProvider>
          </ModalDialog>
          <ModalDialog
            textTitleModal={'Transferir Cliente'}
            isOpen={isOpenChangeEmployee}
            onClose={onCloseChangeEmployee}
            modalSize={'md'}
          >
            <FormProvider {...formMethodsChangeEmployee}>
              <FormChangeEmployee
                barberoActual={employeeSelected?.empleado}
                onSubmit={handleOpenConfirmChangeEmployee}
                initData={listEmpleados}
                item={itemSelected}
              />
            </FormProvider>
          </ModalDialog>
          <AlertsDialog
            titulo={'Transferir Cliente'}
            isOpen={isOpenAlertChangeEmployee}
            onClose={onCloseAlertChangeEmployee}
            handleEvent={handleConfirmChangeEmployee}
            showCancelButton
          >
            Pulsa Aceptar para confirmar la transferencia del cliente
          </AlertsDialog>
        </Flex>

      </Box>
    </Flex>

  )
}

export default RegistroDiario