import React, { useContext } from 'react'
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  useDisclosure,
  Flex,
  IconButton,
} from '@chakra-ui/react'
import { FiMenu, FiHome, FiLogOut, FiTool } from 'react-icons/fi'
import PersonalCard from '../components/PersonalCard'
import ItemSidebar from '../components/ItemSidebar'
import UserContext from '../services/UserContext'
import { FaCashRegister } from 'react-icons/fa'
import { RiFileList2Fill, RiMoneyEuroCircleFill } from 'react-icons/ri'
import { GiPayMoney } from 'react-icons/gi'

const Sidebar = ({ logout }) => {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const user = useContext(UserContext)

  return (
    <Flex
      mr={2}
    >
      <IconButton icon={<FiMenu />}
        onClick={onOpen}
        variant="link"
        color="#FFFFFF" />
      <Drawer placement='left' onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth='1px'>
            <PersonalCard name={user.name} role={user.role} />
          </DrawerHeader>
          <DrawerBody p={0}>
            <ItemSidebar icon={FiHome} text="Inicio" link="/inicio" onClick={onClose} />
            <ItemSidebar icon={RiMoneyEuroCircleFill} text="Registro Diario" link="/registro-diario" onClick={onClose} />
            <ItemSidebar icon={RiFileList2Fill} text="Registro Mensual" link="/registro-mensual" onClick={onClose} />
            <ItemSidebar icon={FaCashRegister} text="Cuadres de Caja" link="/cuadres-de-caja" onClick={onClose} />
            <ItemSidebar icon={GiPayMoney} text="Gastos" link="/gastos" onClick={onClose} />
            {user.rol === 'Informatico' && (
              <>
                <ItemSidebar icon={FiTool} text="Test" link="/test" onClick={onClose} />
              </>
            )
            }
            <ItemSidebar icon={FiLogOut} text="Logout" isButton onClick={logout} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  )
}

export default Sidebar