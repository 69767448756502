import { collection, doc, getDocs, query, where, getDoc, updateDoc, addDoc, deleteDoc } from 'firebase/firestore'
import moment from 'moment'
import { db } from '../firebase/firebase'
import { getMediosDePago } from './cuadreCajaServices'

let collectionRegistroDiarioRef = collection(db, 'registroDiario')


let empleadosRef = doc(db, 'initData', 'barberos')

// Cargar datos iniciales los barberos
export const getEmpleadosAsync = async () => {
  return await getDoc(empleadosRef)
}

// Insertar un nuevo ingreso
export const saveRegistroDiario = async (newRegistro) => {
  //Si existe el ID actualiza el evento
  delete newRegistro.cliente //elimino la propiedad cliente que no deberia estar alli
  if(newRegistro.id){
    const { id, ...updateRegistro } = newRegistro
    const eventToUpdateRef = doc(db, 'registroDiario', id)
    await updateDoc(eventToUpdateRef, updateRegistro)
    return id
  }
  // Si no existe, entonces lo añade a la coleccion
  else{
    const resultRef = await addDoc(collectionRegistroDiarioRef, newRegistro)
    return resultRef.id
  }
}

export const getTestSubCollections = async () => {
  const subCollectionRef = doc(db,'registroDiario','KOC8iyhhkWkMiDEnxXIs')
  const collections = await getDoc(subCollectionRef)
  if(collections.exists()){
    console.log('Document data:', collections.data())
  } else {
  // doc.data() will be undefined in this case
    console.log('No such document!')
  }

  const q = query(collectionRegistroDiarioRef, where('clientes', 'array-contains', { nombre: 'juan', fecha: 'hoy', metodoPago: 'Tarjeta', valorPagado: 15 }))
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((doc) => {
  // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, ' => ', doc.data())
  })
}

export const getRegistroByFecha = async (fecha) => {
  const queryRef = query(collectionRegistroDiarioRef,
    where('fecha', '==', fecha),
    where('idCuadreCaja', '==', null))
  const resultRef = await getDocs(queryRef)
  const list = []
  resultRef.docs.forEach((doc) => {
    const data = {
      id: doc.id,
      ...doc.data()
    }
    list.push(data)
  })
  return list
}

export const getFechasCuadresPendientes = async () => {
  const queryRef = query(collectionRegistroDiarioRef,
    where('idCuadreCaja', '==', null),
    where('fecha', '!=', moment().format('YYYY-MM-DD')))
  const resultRef = await getDocs(queryRef)
  const list = []
  resultRef.docs.forEach((doc) => {
    const data = {
      id: doc.id,
      ...doc.data()
    }
    list.push(data)
  })
  return list
}

export const getRegistrosForHistoricoByIdCuadre = async (idCuadre) => {
  const queryRef = query(collectionRegistroDiarioRef,
    where('idCuadreCaja', '==', idCuadre))
  const resultRef = await getDocs(queryRef)
  const list = []
  resultRef.docs.forEach((doc) => {
    const data = {
      id: doc.id,
      ...doc.data()
    }
    list.push(data)
  })

  const medioPago = await getMediosDePago()

  return { mediosDePago:  medioPago.data().mediosDePago, datos: list }
}

export const deleteDetalleFromRegistro = async (registro) => {
  const { id, ...updateRegistro } = registro
  const eventToUpdateRef = doc(db, 'registroDiario', id)
  await updateDoc(eventToUpdateRef, updateRegistro)
  return id
}

export const deleteRegistro = async (id) => {
  await deleteDoc(doc(db,'registroDiario',id))
}