import { Timestamp } from 'firebase/firestore'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { ESTADO_GASTO, PERIODICIDAD } from './constantes'

export function currencyFormat(num) {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ' €'
}

export function groupBy(objectArray, property) {
  return objectArray.reduce((data, obj) => {
    const key = obj[property]
    if (!data[key]) {
      data[key] = []
    }
    // Add object to list for given key's value
    data[key].push(obj)
    return data
  }, {})
}

export function getIVA(base, pctje = 0) {
  let result = 0
  switch (pctje) {
  case 0:
    result
    break
  case 4:
    result = (base * 0.04)
    break
  case 10:
    result = (base * 0.10)
    break
  case 21:
    result = (base * 0.21)
    break
  default:
    result
    break
  }

  return result.toFixed(2)
}

export function getDataIntervalos(item) {
  let months
  switch (item.periodicidad) {
  case PERIODICIDAD.MENSUAL:
    months = 1
    break
  case PERIODICIDAD.TRIMESTRAL:
    months = 3
    break
  case PERIODICIDAD.SEMESTRAL:
    months = 6
    break
  case PERIODICIDAD.ANUAL:
    months = 12
    break
  default:
    months = 1
    break
  }
  const datosPeriodos = [
    {
      id: uuidv4(),
      dpFechaGasto: Timestamp.fromDate(item.fechaGasto),
      dpSubtotal: item.subtotal,
      dpIVA: item.IVA,
      dpEstadoGasto: item.estadoGasto
    }
  ]
  let mesObjetivo = months
  for (var cont = 1; cont <= item.duracionIntervalo; cont++) {
    console.log('mesObjetivoAntes', mesObjetivo)
    datosPeriodos.push({
      id: uuidv4(),
      dpFechaGasto: Timestamp.fromDate(new Date(moment(item.fechaGasto).month(item.fechaGasto.getMonth() + mesObjetivo))),
      dpSubtotal: item.subtotal,
      dpIVA: item.IVA,
      dpEstadoGasto: ESTADO_GASTO.PENDIENTE
    })
    mesObjetivo += months
    console.log('mesObjetivoDespues', mesObjetivo)
  }
  console.log('datosPeriodos', datosPeriodos)

  return datosPeriodos
}


{/* {item[element] &&
    <Tfoot fontWeight={'bold'}>
      <Tr>
        <Td colSpan={2} textAlign={'right'}>Total</Td>
        <Td>{currencyFormat(item[element]?.reduce((total, item) => total + item.valorPagado, 0))}</Td>
      </Tr>
    </Tfoot>
  } */}