import { Th } from '@chakra-ui/table'
import React from 'react'
import { currencyFormat } from '../utils/functions'

const FooterTableRegistroMensual = ({ data }) => {
  return(
    <>
      <Th isNumeric>{data.reduce((total, item) => total + item.detalle.length, 0)}</Th>
      <Th isNumeric>{currencyFormat(data.reduce((total, item) => total + item.detalle.filter(x => x.metodoPago === 'Efectivo').reduce((subTotal, subItem) => subTotal + subItem.valorPagado, 0),0))}</Th>
      <Th isNumeric>{currencyFormat(data.reduce((total, item) => total + item.detalle.filter(x => x.metodoPago === 'Tarjeta').reduce((subTotal, subItem) => subTotal + subItem.valorPagado, 0),0))}</Th>
      <Th isNumeric borderRight={'1px'}>{currencyFormat(data.reduce((total, item) => total + item.detalle.reduce((subTotal, subItem) => subTotal + subItem.valorPagado, 0),0))}</Th>
    </>
  )
}

export default FooterTableRegistroMensual