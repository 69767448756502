import { Th } from '@chakra-ui/table'
import React from 'react'

const HeadersTableRegistroMensual = () => {
  return(
    <>
      <Th isNumeric borderLeft={'1px'}>Cantidad</Th>
      <Th isNumeric>Efectivo</Th>
      <Th isNumeric>Tarjeta</Th>
      <Th isNumeric borderRight={'1px'}>Total</Th>
    </>
  )
}

export default HeadersTableRegistroMensual