import { Avatar, Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'

const OptionBarbero = (props) => {
  return (
    <Flex ma={2} w={'100%'} pa={4} gap={3} alignItems={'center'} >
      <Box>
        <Avatar size={'lg'} name={props.empleado} src={`/images/${props.empleado}.jpg`}  />
      </Box>
      <Box>
        <Text fontWeight={'bold'}>{props.empleado}</Text>
      </Box>
    </Flex>
  )
}

export default OptionBarbero