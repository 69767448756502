import { collection, doc, getDocs, query, where, getDoc, addDoc, updateDoc, writeBatch } from 'firebase/firestore'
import { db } from '../firebase/firebase'

let collectionRegistroDiarioRef = collection(db, 'registroDiario')
let mediosDePagoRef = doc(db, 'initData', 'medioDePago')
let collectionCuadreCajaRef = collection(db, 'cuadreCaja')

// Cargar datos iniciales medios de pago
export const getMediosDePago = async () => {
  return await getDoc(mediosDePagoRef)
}

export const getRegistrosForCuadreByFecha = async (fecha) => {
  const queryRef = query(collectionRegistroDiarioRef,
    where('fecha', '==', fecha),
    where('idCuadreCaja', '==', null))
  const resultRef = await getDocs(queryRef)
  const tempList = []
  resultRef.docs.forEach((doc) => {
    const data = {
      id: doc.id,
      ...doc.data()
    }
    tempList.push(data)
  })

  const medioPago = await getMediosDePago()

  return { mediosDePago:  medioPago.data().mediosDePago, datos: tempList }
}


// Insertar un nuevo cuadre de caja
export const saveCuadreCaja = async (newData, fullData) => {
  //Si existe el ID actualiza los datos
  const { idCuadreCaja, ...updateData } = newData
  if(newData.id){
    const updateRef = doc(db, 'cuadreCaja', idCuadreCaja)
    await updateDoc(updateRef, updateData)
    return idCuadreCaja
  }
  // Si no existe, entonces lo añade a la coleccion
  else{
    const batch = writeBatch(db)
    const resultRef = await addDoc(collectionCuadreCajaRef, newData)
    const idCuadre = resultRef.id

    fullData.forEach(itemData => {
      const updateRef = doc(db, 'registroDiario', itemData.id)
      batch.set(updateRef, { ...itemData, idCuadreCaja: idCuadre })
    })
    await batch.commit()
    return idCuadre
  }
}

export const getHistoricoCuadresByFecha = async (fechaInicio, fechaFin) => {
  const queryRef = query(collectionCuadreCajaRef,
    where('fecha', '>=', fechaInicio),
    where('fecha', '<=', fechaFin))
  const resultRef = await getDocs(queryRef)
  const tempList = []
  resultRef.docs.forEach((doc) => {
    const data = {
      id: doc.id,
      ...doc.data()
    }
    tempList.push(data)
  })

  return tempList
}
