import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button
} from '@chakra-ui/react'

const ModalDialog = ({
  textTitleModal,
  children,
  textCloseButton = 'Cerrar',
  textSaveButton,
  isOpen,
  onClose,
  modalSize = 'md'
}) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    size={['sm',modalSize]}
    closeOnOverlayClick={false}
    scrollBehavior={'inside'}
    //blockScrollOnMount={true}
    useInert={false}
    isCentered
  >
    <ModalOverlay />
    <ModalContent>
      <ModalHeader bg={'blackAlpha.900'} color={'whiteAlpha.900'}>{textTitleModal}</ModalHeader>
      <ModalCloseButton color={'whiteAlpha.900'} onClick={onClose} />
      <ModalBody>
        {children}
      </ModalBody>
      <ModalFooter>
        <Button colorScheme='red' mr={3} onClick={onClose}>{textCloseButton}</Button>
        {textSaveButton &&
            <Button variant='solid' colorScheme={'blackSoft'}>{textSaveButton}</Button>}
      </ModalFooter>
    </ModalContent>
  </Modal>
)

export default ModalDialog