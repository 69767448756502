import React from 'react'
import { Flex } from '@chakra-ui/react'

const Appbar = ({ children }) => {
  return (
    <Flex className='primary-color'
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      px={4}
      color="white"
    >
      {children}
    </Flex>
  )
}

export default Appbar