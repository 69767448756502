import { Alert, AlertIcon, Box, Container, Divider, Flex, Spinner } from '@chakra-ui/react'
import React from 'react'
import EmployeeCard from '../components/EmployeeCard'
import StatsMensuales from '../components/StatsMensuales'
import TableRegistroMensualXEmpleado from '../components/TableRegistroMensualXEmpleado'
import FormGetRegistroMensual from './FormGetRegistroMensual'

const RegistroMensualXEmpleado = ({ mesActual, empleadoSelected, onSubmitEvent, isSearching, listResult, handleSelectRegistro }) => {
  return (
    <>
      <Flex justify={'center'} flexDir={'column'} gap={3}>
        <Box>
          <Flex justify={'center'}>
            <FormGetRegistroMensual onSubmit={onSubmitEvent} showEmpleado />
          </Flex>
          <Divider my={5} />
          {empleadoSelected.length &&
                        <Flex justify={'center'} my={5}>
                          <EmployeeCard employee={{ empleado: empleadoSelected }} >
                                Datos del mes de {mesActual}
                          </EmployeeCard>
                        </Flex>
          }
        </Box>
        <Flex gap={5} pb={6}>
          {isSearching ?
            <Flex justifyContent={'center'} w='100%'>
              <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blackSoft'
                size='xl'
              />
            </Flex>
            :
            listResult.length > 0 &&
                        <StatsMensuales listResult={listResult} />
          }
        </Flex>
        <Container>

          <Flex flexDir={'column'} gap={5}>
            {empleadoSelected.length &&
                            listResult.length ?
              <TableRegistroMensualXEmpleado data={listResult} handleOnClickSelectRegistro={handleSelectRegistro} />
              :
              <Alert status='info' rounded={'10px'}>
                <AlertIcon />
                                Aun no hay registros
              </Alert>
            }

          </Flex>
        </Container>
      </Flex>
    </>
  )
}

export default RegistroMensualXEmpleado