import React from 'react'
import { Alert, AlertIcon, Flex, Spinner } from '@chakra-ui/react'

import TableGastosFijos from '../components/TableGastosFijos'

const GastosFijos = ({ listGastosFijos, handleEditGasto, handleDeleteGasto, isSearching }) => {

  return (
    <Flex flexDir={'column'} gap={5} >
      <Flex gap={5} w={'100%'}>
        {isSearching ?
          <Flex justifyContent={'center'} w='100%'>
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blackSoft'
              size='xl'
            />
          </Flex>
          :
          listGastosFijos.length > 0 ?
            <TableGastosFijos
              data={listGastosFijos}
              handleEditGasto={handleEditGasto}
              handleDeleteGasto={handleDeleteGasto}
            />
            :
            <Alert status='info' rounded={'10px'}>
              <AlertIcon />
              No hay registros para el mes seleccionado
            </Alert>
        }
      </Flex>
    </Flex>

  )
}

export default GastosFijos