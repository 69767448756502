import React, { useRef } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Button,
} from '@chakra-ui/react'

// eslint-disable-next-line no-unused-vars
const AlertsDialog = ({
  titulo,
  children,
  isOpen,
  onClose,
  handleEvent,
  showCancelButton = false,
  colorOkBtn = 'green',
  modalSize = 'md'
}) => {

  const cancelRef = useRef()

  return (
    <AlertDialog
      motionPreset='slideInBottom'
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      size={modalSize}
      scrollBehavior={'inside'}
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>{titulo}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          {children}
        </AlertDialogBody>
        <AlertDialogFooter>
          {showCancelButton &&
            <Button ref={cancelRef} onClick={onClose}>
              No
            </Button>
          }
          <Button colorScheme={colorOkBtn} ml={3} onClick={handleEvent}>
            Aceptar
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default AlertsDialog