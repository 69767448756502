import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import React, { StrictMode } from 'react'
import * as ReactDOM from 'react-dom/client'
import UserContainer from './containers/UserContainer'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'
import 'moment/locale/es'
import '@fontsource/poppins'
import '@fontsource/open-sans'
import './assets/App.css'
import { createStandaloneToast } from '@chakra-ui/toast'

import theme from './assets/theme'

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container)
const { ToastContainer } = createStandaloneToast()


root.render(
  <StrictMode>
    <ColorModeScript initialColorMode={'light'} useSystemColorMode={false} />
    <ChakraProvider theme={theme} >
      <ToastContainer />
      <UserContainer />
    </ChakraProvider>
  </StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
