import React from 'react'
import { Flex, Stack } from '@chakra-ui/react'
import KPI from '../components/KPI'
import { currencyFormat } from '../utils/functions'
import { useState } from 'react'
import { GiCash, GiMoneyStack } from 'react-icons/gi'
import { SiVisa } from 'react-icons/si'
import { getRegistroByFecha } from '../services/registroDiarioServices'
import { useEffect } from 'react'
import moment from 'moment'

const Inicio = () => {

  const [listRegistroDiario, setListRegistroDiario] = useState([])

  //Funcion para obtener los ingresos del dia
  function getIngresoTotal() {
    return listRegistroDiario.reduce((total, item) =>
      total + item.detalle.reduce((totalDet, det) => totalDet + det.valorPagado, 0), 0)
  }

  //Funcion para obtener los Efectivo del dia
  function getIngresoEfectivoTotal() {
    return listRegistroDiario.reduce((total, item) =>
      total + item.detalle.filter(x => x.metodoPago === 'Efectivo').reduce((totalDet, det) => totalDet + det.valorPagado, 0), 0)
  }

  //Funcion para obtener los Tarjeta del dia
  function getIngresoTarjetaTotal() {
    return listRegistroDiario.reduce((total, item) =>
      total + item.detalle.filter(x => x.metodoPago === 'Tarjeta').reduce((totalDet, det) => totalDet + det.valorPagado, 0), 0)
  }

  // Metodo para obtener los registros x fecha
  const getRegistroDeHoy = async (fecha) => {
    const data = await getRegistroByFecha(fecha)
    if (data.length !== 0) {
      setListRegistroDiario(data)
    }
  }

  //Efecto para cargar los ingresos del dia
  useEffect(() => {
    async function obtenerDatos() {
      await getRegistroDeHoy(moment().format('YYYY-MM-DD'))
      // await getListEmpleados()
    }
    obtenerDatos()
  }, [])
  return (
    <Flex flexDir={'column'} gap={5} m={6} w={'100%'}>
      <Stack direction={['column', 'row']} spacing='24px' >
        <KPI title={'Ingresos del Dia'} value={currencyFormat(getIngresoTotal())} icon={GiCash} />
        <KPI title={'Ingresos Tarjeta'} value={currencyFormat(Number(getIngresoTarjetaTotal()))} icon={SiVisa} iconColor={'darkBlue'} />
        <KPI title={'Ingresos Efectivo'} value={currencyFormat(getIngresoEfectivoTotal())} icon={GiMoneyStack} iconColor={'green'} />
      </Stack>
    </Flex>

  )
}

export default Inicio