import { Avatar, Flex, Text } from '@chakra-ui/react'
import React from 'react'


const PersonalCard = ({ name, role }) => {
  return (
    <Flex
      flexDir='column'
      alignItems="center"
    >
      <Avatar name={name} size="xl" />
      <Text fontSize='md' fontWeight='bold'>{name}</Text>
      <Text fontSize='sm'>{role}</Text>
    </Flex>
  )
}

export default PersonalCard