import React from 'react'
import {
  Divider,
  Flex,
  Heading,
  Text
} from '@chakra-ui/react'
import EmployeeCard from './EmployeeCard'
import ItemDetalleRegistroIngresos from './ItemDetalleRegistroIngresos'
import { currencyFormat } from '../utils/functions'

const DetalleRegistroIngresos = ({ employeeItem, handleEditRegistro, handleChangeEmployee, handleDeleteRegistro, isReadOnly = false }) => {

  return(
    <Flex flexDir={'column'} my={1} alignItems={'center'}>
      <EmployeeCard employee={employeeItem}>
        <Text>Cortes {employeeItem.detalle.length}</Text>
      </EmployeeCard>
      <Flex flexDir={'column'} mt={5} w={'90%'}>
        <Divider colorScheme={'blackSoft'} horientation='horizontal'  />
        {employeeItem.detalle.map((item, index) => (
          <ItemDetalleRegistroIngresos
            key={index}
            item={item}
            isReadOnly={isReadOnly}
            handleEdit={handleEditRegistro}
            handleChange={handleChangeEmployee}
            handleDelete={handleDeleteRegistro}
          />
        ))}
      </Flex>
      <Flex w={'100%'} my={5}  flexDir='column'>
        <Divider colorScheme={'blackSoft'} horientation='horizontal'  />
        <Flex my={2} px={5} justifyContent={'space-between'} alignSelf={'center'} w={'90%'}>
          <Heading fontWeight={'bold'} fontSize={'lg'}>Tarjeta ({employeeItem.detalle.filter(item => item.metodoPago === 'Tarjeta').length})</Heading>
          <Heading fontWeight={'bold'} fontSize={'lg'}>{currencyFormat(employeeItem.detalle.filter(item => item.metodoPago === 'Tarjeta').reduce((total, data) => total + data.valorPagado, 0))}</Heading>
        </Flex>
        <Flex my={2} px={5} justifyContent={'space-between'} alignSelf={'center'} w={'90%'}>
          <Heading fontWeight={'bold'} fontSize={'lg'}>Efectivo ({employeeItem.detalle.filter(item => item.metodoPago === 'Efectivo').length})</Heading>
          <Heading fontWeight={'bold'} fontSize={'lg'}>{currencyFormat(employeeItem.detalle.filter(item => item.metodoPago === 'Efectivo').reduce((total, data) => total + data.valorPagado, 0))}</Heading>
        </Flex>
        <Flex my={5} px={5} justifyContent={'space-between'} alignSelf={'center'} w={'90%'}>
          <Heading fontWeight={'bold'} fontSize={'2xl'}>Total ({employeeItem.detalle.length})</Heading>
          <Heading fontWeight={'bold'} fontSize={'2xl'}>{currencyFormat(employeeItem.detalle.reduce((total, item) => total + item.valorPagado, 0))}</Heading>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default DetalleRegistroIngresos