export const TIPO_GASTO = {
  FIJO: 'Fijo',
  VARIABLE: 'Variable'
}

export const ESTADO_GASTO = {
  PENDIENTE : 'Pendiente',
  PAGADO: 'Pagado'
}

export const PERIODICIDAD = {
  MENSUAL: 'Mensual',
  TRIMESTRAL: 'Trimestral',
  SEMESTRAL: 'Semestral',
  ANUAL: 'Anual'
}

export const MEDIO_PAGO = {
  EFECTIVO: 'Efectivo',
  TARJETA: 'Tarjeta'
}