import { Box, Table, Tbody, Td, Tfoot, Th, Thead, Tr } from '@chakra-ui/react'
import React from 'react'
import ContentTableRegistroMensual from '../containers/ContentTableRegistroMensual'
import FooterTableRegistroMensual from '../containers/FooterTableRegistroMensual'
import HeadersTableRegistroMensual from '../containers/HeadersTableRegistroMensual'

const TableRegistroMensual = ({ daysOfMonth, listData, listResultTotal, initData, handleOnClickSelectRegistro }) => {

  return (
    <Box p={4}
      bgColor={'white'}
      borderRadius={10}
      className={'jmz-outline'}
      w={'100%'}
    >
      <Box className={'jmz-wrapper'}>
        <Box className='jmz-scroll'>
          <Table variant='striped' size={'sm'} className={'jmz-table'}>
            <Thead>
              <Tr>
                <Th className='jmz-sticky-col'>Mr.Champagne</Th>
                {initData.map((header, index) => {
                  return (
                    <Th key={index} colSpan={4} borderLeft={'1px'} borderRight={'1px'}>{header}</Th>
                  )
                }
                )}
              </Tr>
              <Tr>
                <Th className='jmz-sticky-col'>Fecha</Th>
                {initData.map((date, index) => {
                  return (
                    <HeadersTableRegistroMensual key={index} />
                  )
                }
                )}
              </Tr>
            </Thead>
            <Tbody>
              {daysOfMonth.map((date, index) => (
                <Tr key={index}>
                  <Td className='jmz-sticky-col'>{date}</Td>
                  {initData.map((empleado, indexBarber) => {
                    if (listData[date].some(item => item.fecha === date && item.empleado === empleado)) {
                      return <ContentTableRegistroMensual key={indexBarber} data={listData[date].filter(item => item.fecha === date && item.empleado === empleado)} handleOnClickSelectRegistro={handleOnClickSelectRegistro} />
                    } else {
                      return <ContentTableRegistroMensual key={indexBarber} />
                    }
                  }
                  )}
                </Tr>
              ))}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th className='jmz-sticky-col'>Total</Th>
                {initData.map((empleado, indexFooter) => (
                  <FooterTableRegistroMensual key={indexFooter} data={listResultTotal.filter(item => item.empleado === empleado)} />
                )
                )}
              </Tr>
            </Tfoot>
          </Table>
        </Box>
      </Box>
    </Box>
  )
}

export default TableRegistroMensual