import React from 'react'
import {
  Flex,
  Icon,
  Link,
  Menu,
  MenuButton,
  Text
} from '@chakra-ui/react'
import { Link as LinkRouter } from 'react-router-dom'

const ItemSidebar = ({ icon, text, link, onClick, isButton }) => {
  return (
    <Flex mt={3}
      w="100%"
      flexDir="column"
      align="flex-start"
    >
      <Menu placement="right">
        {isButton ?
          <MenuButton
            p={3}
            w="100%"
            _hover={{ textDecor: 'none', backgroundColor: '#000000', color: '#FFFFFF' }}
            onClick={onClick}
          >
            <Flex alignItems="center">
              <Icon as={icon}
                mx={3} fontSize="xl" />
              <Text>{text}</Text>
            </Flex>
          </MenuButton>
          :
          <Link p={3}
            w="100%"
            _hover={{ textDecor: 'none', backgroundColor: '#000000', color: '#FFFFFF' }}
            as={LinkRouter}
            to={link}
            onClick={onClick}
          >
            <MenuButton w="100%" >
              <Flex alignItems="center">
                <Icon as={icon}
                  mx={3} fontSize="xl" />
                <Text>{text}</Text>
              </Flex>
            </MenuButton>
          </Link>
        }

      </Menu>
    </Flex>
  )
}

export default ItemSidebar