import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import moment from 'moment/moment'
import { getAllRegistrosByBarbero } from '../services/registroMensualServices'
import RegistroMensualXMes from '../containers/RegistroMensualXMes'
import { getEmpleadosAsync } from '../services/registroDiarioServices'
import { getAllRegistrosMesActual } from '../services/registroMensualServices'
import { groupBy } from '../utils/functions'
import RegistroMensualXEmpleado from '../containers/RegistroMensualXEmpleado'
import ModalDialog from '../components/ModalDialog'
import DetalleRegistroIngresos from '../components/DetalleRegistroIngresos'

const RegistroMensual = () => {

  //variables para detalle registro
  const { isOpen: isOpenDetalleRegistro, onOpen: onOpenDetalleRegistro, onClose: onCloseDetalleRegistro } = useDisclosure()
  const [itemSelected, setItemSelected] = useState({})


  //datos para el listado x barbero
  const [listRegistroMensualEmpleado, setListRegistroMensualEmpleado] = useState([])
  const [empleadoSelected, setEmpleadoSelected] = useState({})

  //datos para el listado x mes
  const [initData, setInitData] = useState([])
  const [listResult, setListResult] = useState([])
  const [listResultByFecha, setListResultByFecha] = useState({})
  const [listDaysOfMonth, setListDaysOfMonth] = useState([])
  const [mesActual, setMesActual] = useState('')
  const [isSearchingInfo, setIsSearchingInfo] = useState(false)

  //Abrir el detalle de cada empleado
  const handleOpenDetalleRegistro = (employeeItem) => {
    setItemSelected(employeeItem)
    onOpenDetalleRegistro()
  }

  const getDatosMesActual = async (startDate, endDate) => {
    setIsSearchingInfo(true)
    const data = await getAllRegistrosMesActual(startDate, endDate)
    if (data.length !== 0) {
      setListResult(data)
      setListResultByFecha(groupBy(data, 'fecha'))
      let daysResult = groupBy(data, 'fecha')
      setListDaysOfMonth(Object.keys(daysResult))
    } else {
      setListResult([])
      setListResultByFecha([])
      setListDaysOfMonth([])
    }
    setIsSearchingInfo(false)
  }

  const onSubmitData = async (datos) => {
    //console.log('entro datos ', datos)
    if (datos.empleado) {
      setEmpleadoSelected(datos.empleado)
      let startDate = (moment(datos.mesSelected).format('YYYY-MM-DD'))
      let endDate = (moment(datos.mesSelected).endOf('month').format('YYYY-MM-DD'))
      const data = await getAllRegistrosByBarbero(datos.empleado, startDate, endDate)
      setMesActual(moment(datos.mesSelected).format('MMMM YYYY'))
      if (data.length !== 0) {
        setListRegistroMensualEmpleado(data)
      }
    } else {
      let startDate = (moment(datos.mesSelected).format('YYYY-MM-DD'))
      let endDate = (moment(datos.mesSelected).endOf('month').format('YYYY-MM-DD'))
      setMesActual(moment(datos.mesSelected).format('MMMM YYYY'))
      await getDatosMesActual(startDate, endDate)
    }

  }
  const formData = useForm()

  // Hook para resetar despues de enviar
  useEffect(() => {
    if (formData.formState.isSubmitSuccessful) {
      formData.reset()
    }
  }, [formData, formData.formState.isSubmitSuccessful, formData.reset])

  useEffect(() => {
    setEmpleadoSelected({})
  }, [])

  // Metodo para cargar los empleados/barberos
  const getListEmpleados = async () => {
    const result = await getEmpleadosAsync()
    if (result.length !== 0) {
      setInitData(result.data().empleados)
    }
  }
  //Efecto para cargar los ingresos del mes
  useEffect(() => {
    async function obtenerDatos() {
      let startDate = (moment().startOf('month').format('YYYY-MM-DD'))
      let endDate = (moment().endOf('month').format('YYYY-MM-DD'))
      setMesActual(moment().format('MMMM YYYY'))
      await getDatosMesActual(startDate, endDate)
      await getListEmpleados()

    }
    obtenerDatos()
  }, [])
  return (
    <Flex w={'100%'}>
      <Box className='jmz-paper' height={{ base: '100%', lg: 'auto' }}>
        <div className='jmz-card-header'>
          <div className='jmz-card-title'>
            Registro Mensual
          </div>
        </div>
        <Flex p={3} gap={6} mt={2} mb={6}>
          <Tabs size='md' variant='enclosed-colored' colorScheme={'blackSoft'} w={'100%'} isLazy >
            <TabList>
              <Tab>Filtro por meses</Tab>
              <Tab>Filtro por empleado</Tab>
            </TabList>
            <TabPanels>
              <TabPanel px={0}>
                <FormProvider {...formData} >
                  <RegistroMensualXMes
                    onSubmitEvent={onSubmitData}
                    initData={initData}
                    listResult={listResult}
                    listResultByFecha={listResultByFecha}
                    listDaysOfMonth={listDaysOfMonth}
                    mesActual={mesActual}
                    isSearching={isSearchingInfo}
                    handleSelectRegistro={handleOpenDetalleRegistro}
                  />
                </FormProvider>
              </TabPanel>
              <TabPanel px={0} >
                <FormProvider {...formData} >
                  <RegistroMensualXEmpleado
                    empleadoSelected={empleadoSelected}
                    onSubmitEvent={onSubmitData}
                    listResult={listRegistroMensualEmpleado}
                    mesActual={mesActual}
                    handleSelectRegistro={handleOpenDetalleRegistro}
                  />
                </FormProvider>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </Box>
      <ModalDialog
        textTitleModal={'Detalle Registro'}
        isOpen={isOpenDetalleRegistro}
        onClose={onCloseDetalleRegistro}
        modalSize={'md'}
      >
        <DetalleRegistroIngresos
          employeeItem={itemSelected}
          isReadOnly
        />
      </ModalDialog>
    </Flex>
  )
}

export default RegistroMensual