import React, { useEffect } from 'react'
import {
  Flex
} from '@chakra-ui/react'
import Sidebar from './containers/Sidebar'
import Appbar from './components/Appbar'
import LogoAppBar from './components/LogoAppBar'
import Content from './components/Content'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth'
import { getUserDataByUid } from './services/UserServices'
import { ShowToast } from './components/Toast'

const App = ({ userAuth }) => {

  const navigate = useNavigate()
  const auth = getAuth()
  const location = useLocation()

  const handleLogout = () => {
    signOut(auth).then(() => {
      console.log('voy a remover el token')
      localStorage.removeItem('Auth Token')
      navigate('/login')
    }).catch((error) => {
      ShowToast('', error, 'error')
    })
  }

  const checkLogUser = () => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userData = await getUserDataByUid(user.uid)
        await userAuth(userData)
        if(location.pathname === '/'){
          navigate('/registro-diario')
        }
      } else {
        handleLogout()
      }
    })
  }

  useEffect(() => {
    console.log('Efecto de App')
    let authToken = window.localStorage.getItem('Auth Token')

    if (authToken) {
      checkLogUser()
    }
    if(!authToken){
      navigate('/login')
    }
  }, [])

  return (
    <div>
      <Appbar>
        <Flex>
          <Sidebar logout={handleLogout} />
          <LogoAppBar />
        </Flex>
      </Appbar>
      <Content>
        <Outlet />
      </Content>
    </div>
  )
}

export default App
