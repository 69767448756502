import { Flex, Table, Thead, Tbody, Tr, Th, Td, Tfoot, chakra, IconButton, Badge, Box, TableContainer } from '@chakra-ui/react'
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import { createColumnHelper, flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import moment from 'moment/moment'
import React, { useState } from 'react'
import { currencyFormat } from '../utils/functions'
import { MdOutlineRemoveRedEye } from 'react-icons/md'
import { RiDeleteBin2Fill } from 'react-icons/ri'
import { ESTADO_GASTO, MEDIO_PAGO } from '../utils/constantes'
import { GiMoneyStack } from 'react-icons/gi'
import { GoCreditCard } from 'react-icons/go'

const TableGastosVariables = ({ data, handleEditGasto, handleDeleteGasto }) => {

  const [sorting, setSorting] = useState([])

  //const [data, setData] = useState([...gastosDataTest])
  const columnHelper = createColumnHelper()

  const columns = [
    columnHelper.accessor('fechaGasto', {
      cell: data => moment(new Date(data.getValue().toDate())).format('YYYY-MM-DD'),
      header: () => <span>Fecha Gasto</span>
    }),
    // columnHelper.accessor('id', {
    //   cell: data => data.getValue(),
    //   header: () => <span>ID</span>
    // }),
    columnHelper.accessor('tipoGasto', {
      cell: data => data.getValue(),
      header: () => <span>Tipo Gasto</span>
    }),
    columnHelper.accessor('categoriaGasto', {
      cell: data => data.getValue(),
      header: () => <span>Categoria</span>
    }),
    columnHelper.accessor('nota', {
      cell: data => data.getValue(),
      header: () => <span>Nota</span>
    }),
    columnHelper.accessor('subtotal', {
      cell: data => currencyFormat(Number(data.getValue())),
      header: () => <span>Subtotal</span>,
      footer: () => <span>{currencyFormat(data.reduce((acc, item) => acc + Number(item.subtotal), 0))}</span>
    }),
    columnHelper.accessor('IVA', {
      cell: data => currencyFormat(Number(data.getValue())),
      header: () => <span>IVA</span>,
      footer: () => <span>{currencyFormat(data.reduce((acc, item) => acc + Number(item.IVA), 0))}</span>
    }),
    columnHelper.accessor('pctjeIVA', {
      cell: data => `${data.getValue()} %`,
      header: () => <span>% IVA</span>
    }),
    columnHelper.accessor('total', {
      cell: data => currencyFormat(Number(data.getValue())),
      header: () => <span>Total</span>,
      footer: () => <span>{currencyFormat(data.reduce((acc, item) => acc + Number(item.total), 0))}</span>
    }),
    columnHelper.accessor('medioPago', {
      cell: data => (<Badge variant={'outline'}
        p={2}
        display={'flex'}
        alignItems={'center'}
        gap={3}
        colorScheme={data.getValue() === MEDIO_PAGO.EFECTIVO ? 'blackSoft' : 'facebook'}
      >
        {data.getValue() === MEDIO_PAGO.EFECTIVO ? <GiMoneyStack fontSize={18} /> : <GoCreditCard fontSize={18} />}
        {data.getValue()}
      </Badge>),
      header: () => <span>Medio Pago</span>
    }),
    columnHelper.accessor('estadoGasto', {
      cell: data => <Badge variant='subtle' colorScheme={data.getValue() === ESTADO_GASTO.PENDIENTE ? 'orange' : 'green'}>{data.getValue()}</Badge>,
      header: () => <span>Estado</span>,
    }),
    columnHelper.display({
      id: 'Accion',
      cell: props => {
        return (
          <Flex gap={2}>
            <IconButton variant={'solid'} size={'sm'} colorScheme={'blackSoft'} icon={<MdOutlineRemoveRedEye />} onClick={() => handleEditGasto(props.row.original)} />
            <IconButton variant={'solid'} size={'sm'} colorScheme={'red'} icon={<RiDeleteBin2Fill />} onClick={() => handleDeleteGasto(props.row.original)} />
          </Flex>
        )
      },
      header: () => <span>Accion</span>
    })
  ]

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()

  })

  return (
    <Box p={4}
      bgColor={'white'}
      borderRadius={10}
      className={'jmz-outline'}
      w={'100%'}
    >
      <TableContainer>
        <Table variant={'striped'}>
          <Thead>
            {table.getHeaderGroups().map(headerGroup => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <Th key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : (
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            <chakra.span pl="4">
                              {header.column.getIsSorted() ? (
                                header.column.getIsSorted() === 'desc' ? (
                                  <TriangleDownIcon aria-label="sorted descending" />
                                ) : (
                                  <TriangleUpIcon aria-label="sorted ascending" />
                                )
                              ) : null}
                            </chakra.span>
                          </div>
                        )}
                    </Th>
                  )
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map(row => (
              <Tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <Td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
          <Tfoot>
            {table.getFooterGroups().map(footerGroup => (
              <Tr key={footerGroup.id}>
                {footerGroup.headers.map(header => (
                  <Th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Tfoot>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default TableGastosVariables