// import { collection, doc, getDocs, query, where, getDoc, updateDoc, addDoc, deleteDoc } from 'firebase/firestore'
// import moment from 'moment'
import { db } from '../firebase/firebase'
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore'
import { TIPO_GASTO } from '../utils/constantes'
// import { getMediosDePago } from './cuadreCajaServices'

const collecionGastosFijos = collection(db, 'gastos')

const initDataGastos = doc(db, 'initData', 'dataGastos')
const categoriaGasto = doc(db, 'initData', 'categoriaGastos')

export const getInitDataGastos = async () => {
  return await getDoc(initDataGastos)
}

export const getCategoriaGastos = async () => {
  return await getDoc(categoriaGasto)
}

// Insertar un nuevo ingreso
export const saveGasto = async (registro) => {
  //Si existe el ID actualiza el evento
  if (registro.id) {
    const { id, ...updateRegistro } = registro
    const eventToUpdateRef = doc(db, 'gastos', id)
    await updateDoc(eventToUpdateRef, updateRegistro)
    return id
  }
  // Si no existe, entonces lo añade a la coleccion
  else {
    const resultRef = await addDoc(collecionGastosFijos, registro)
    return resultRef.id
  }
}

export const getAllGastosByFechas = async (fechaInicio, fechaFin) => {
  //Gastos Variables
  const queryRefGastosVariables = query(collecionGastosFijos,
    where('fechaGasto', '>=', new Date(fechaInicio)),
    where('fechaGasto', '<=', new Date(fechaFin)),
    where('tipoGasto', '==', TIPO_GASTO.VARIABLE))
  const resultRefGastosVariables = await getDocs(queryRefGastosVariables)
  const list = []
  resultRefGastosVariables.docs.forEach((doc) => {
    const data = {
      id: doc.id,
      ...doc.data()
    }
    list.push(data)
  })
  //Gastos Variables
  const queryRefGastosFijos = query(collecionGastosFijos,
    where('isActivo', '==', true),
    where('tipoGasto', '==', TIPO_GASTO.FIJO))
  const resultRefGastosFijos = await getDocs(queryRefGastosFijos)
  resultRefGastosFijos.docs.forEach((doc) => {
    const data = {
      id: doc.id,
      ...doc.data()
    }
    list.push(data)
  })
  return list
}

export const deleteRegistroGasto = async (id) => {
  await deleteDoc(doc(db, 'gastos', id))
}