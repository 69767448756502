import { Alert, AlertIcon } from '@chakra-ui/alert'
import { Box, Divider, Flex, Heading } from '@chakra-ui/layout'
import React from 'react'
import TableRegistroMensual from '../components/TableRegistroMensual'
import FormGetRegistroMensual from './FormGetRegistroMensual'
import { Spinner } from '@chakra-ui/react'
import StatsMensuales from '../components/StatsMensuales'

const MesActualRegistroMensual = ({ onSubmitEvent, initData, listResult, listResultByFecha, listDaysOfMonth, mesActual, isSearching, handleSelectRegistro }) => {



  return (
    <>
      <Flex justify={'center'} flexDir={'column'} gap={3}>
        <Box>
          <Flex justify={'center'}>
            <FormGetRegistroMensual onSubmit={onSubmitEvent}  />
          </Flex>
          <Divider my={5} />
          <Box className='jmz-current-month'>
            <Heading size={'sm'}>Datos de</Heading>
            <Heading>{mesActual}</Heading>
          </Box>
        </Box>
        <Flex gap={5} pb={6}>
          {isSearching ?
            <Flex justifyContent={'center'} w='100%'>
              <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blackSoft'
                size='xl'
              />
            </Flex>
            :
            listResult.length > 0 &&
              <StatsMensuales listResult={listResult} isGlobal />
          }
        </Flex>
      </Flex>
      {listResult.length ?
        <TableRegistroMensual daysOfMonth={listDaysOfMonth} listData={listResultByFecha} listResultTotal={listResult} initData={initData} handleOnClickSelectRegistro={handleSelectRegistro} />
        :
        <Alert status='info' rounded={'10px'}>
          <AlertIcon />
          No hay registros para el mes seleccionado
        </Alert>

      }
    </>
  )
}

export default MesActualRegistroMensual