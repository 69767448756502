import React, { useEffect, useState } from 'react'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
  Box,
  Text,
  Icon
} from '@chakra-ui/react'
import { Controller, useFormContext } from 'react-hook-form'
import { FaEuroSign, FaChevronDown } from 'react-icons/fa'
import { RiSave3Fill } from 'react-icons/ri'
import OptionBarbero from '../components/OptionBarbero'
import { GiMoneyStack } from 'react-icons/gi'
import { SiVisa } from 'react-icons/si'

const FormAddIngresoDiario = ({ onSubmit, initData, showEmpleadoField }) => {
  // Variable para el DropDown
  const [barberoSelected, setBarberoSelected] = useState('')
  const [metodoPagoSelected, setMetodoPagoSelected] = useState('')

  // Declarando el useFormContext
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    reset,
    control,
    formState: { errors, isSubmitting, isSubmitSuccessful }
  } = useFormContext()
  // Hook para resetar despues de enviar
  useEffect(() => {
    if (getValues('metodoPago')) {
      setMetodoPagoSelected(getValues('metodoPago'))
    }
    if (isSubmitSuccessful) {
      setBarberoSelected('')
      setMetodoPagoSelected('')
      reset()
    }
  }, [isSubmitSuccessful, reset])
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={'30px'}
      >
        {showEmpleadoField &&
          <FormControl id="empleado" isInvalid={errors.empleado}>
            <FormLabel htmlFor='empleado' fontWeight={'semibold'}>Barbero</FormLabel>
            <Menu matchWidth>
              <MenuButton w={'100%'} py={10} as={Button} rightIcon={<FaChevronDown />}>
                {barberoSelected ?
                  <OptionBarbero empleado={barberoSelected} />
                  :
                  <>
                    Selecciona un barbero
                  </>
                }
              </MenuButton>
              <MenuList zIndex={3} {...register('empleado', { required: 'Selecciona un barbero' })} >
                {initData.map((option, index) => (
                  <MenuItem minH='48px' key={index} onClick={() => { setBarberoSelected(option); setValue('empleado', option); clearErrors('empleado') }}>
                    <OptionBarbero empleado={option} />
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
            {/* <Select placeholder="Selecciona uno" size={'lg'} {...register('empleado', { required: 'Selecciona el empleado' })}
          >
            {initData.map((option, index) => (
              <option className='option-empleado' key={index} value={option.empleado}>{option.empleado}</option>
            ))}
          </Select> */}
            <FormErrorMessage>
              {errors.empleado && errors.empleado.message}
            </FormErrorMessage>
          </FormControl>
        }
        <FormControl id="cliente" isInvalid={errors.cliente}>
          <FormLabel htmlFor='cliente' fontWeight={'semibold'}>Cliente</FormLabel>
          <Controller
            control={control}
            name="cliente"
            rules={{ required: 'Falta el nombre del cliente' }}
            render={({ field: { onChange, value } }) => (
              <Input
                className='jmz-mayusculas'
                size={'lg'}
                onInput={onChange}
                defaultValue={value}
              />
            )}
          />
          <FormErrorMessage>
            {errors.cliente && errors.cliente.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl id="valor-ingreso" isInvalid={errors.valorIngreso}>
          <FormLabel htmlFor='valor-ingreso' fontWeight={'semibold'}>Valor Ingreso</FormLabel>
          <InputGroup>
            <Input placeholder=" " size={'lg'}
              {...register('valorPagado', { required: 'Ingresa un valor', valueAsNumber: true })} />
            <InputRightElement>
              <FaEuroSign color={'green'} fontSize={'25px'} />
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>
            {errors.valorIngreso && errors.valorIngreso.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl id="metodoPago" isInvalid={errors.metodoPago}>
          <FormLabel htmlFor='metodoPago' fontWeight={'semibold'}>Metodo de Pago</FormLabel>
          <Menu matchWidth>
            <MenuButton w={'100%'} py={10} as={Button} rightIcon={<FaChevronDown />}>
              {metodoPagoSelected ?
                <Flex ma={2} w={'100%'} pa={4} gap={3} alignItems={'center'} >
                  <Box>
                    <Icon as={metodoPagoSelected === 'Efectivo' ? GiMoneyStack : SiVisa}
                      fontSize="6xl"
                      color={metodoPagoSelected === 'Efectivo' ? 'green' : 'darkBlue'} />
                  </Box>
                  <Box>
                    <Text fontWeight={'bold'}>{metodoPagoSelected}</Text>
                  </Box>
                </Flex>
                :
                <>
                  Selecciona el método de pago
                </>
              }
            </MenuButton>
            <MenuList  {...register('metodoPago', { required: 'Selecciona el método de pago' })} >
              <MenuItem minH='48px' onClick={() => { setMetodoPagoSelected('Efectivo'); setValue('metodoPago', 'Efectivo'); clearErrors('metodoPago') }}>
                <Flex ma={2} pa={4} gap={3} alignItems={'center'} >
                  <Box>
                    <Icon as={GiMoneyStack}
                      fontSize="6xl"
                      color={'green'} />
                  </Box>
                  <Box>
                    <Text fontWeight={'bold'}>Efectivo</Text>
                  </Box>
                </Flex>
              </MenuItem>
              <MenuItem minH='48px' onClick={() => { setMetodoPagoSelected('Tarjeta'); setValue('metodoPago', 'Tarjeta'); clearErrors('metodoPago') }}>
                <Flex ma={2} pa={4} gap={3} alignItems={'center'} >
                  <Box>
                    <Icon as={SiVisa}
                      fontSize="6xl"
                      color={'darkBlue'} />
                  </Box>
                  <Box>
                    <Text fontWeight={'bold'}>Tarjeta</Text>
                  </Box>
                </Flex>
              </MenuItem>
            </MenuList>
          </Menu>
          {/* <Select placeholder="Selecciona uno" size={'lg'} {...register('metodoPago', { required: 'Selecciona el metodo de pago' })} >
            <option value={'Efectivo'}>Efectivo</option>
            <option value={'Tarjeta'}>Tarjeta</option>
          </Select> */}
          <FormErrorMessage>
            {errors.metodoPago && errors.metodoPago.message}
          </FormErrorMessage>
        </FormControl>
        <Button w="100%" colorScheme={'blackSoft'} size='lg' isLoading={isSubmitting} type={'submit'} leftIcon={<RiSave3Fill />}>GUARDAR</Button>
      </VStack>
    </form>
  )
}

export default FormAddIngresoDiario