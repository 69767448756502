import React, { useEffect, useState } from 'react'
import { Button, Divider, Flex, Heading, Input, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import { getHistoricoCuadresByFecha } from '../services/cuadreCajaServices'
import moment from 'moment'
import { currencyFormat } from '../utils/functions'
import AlertsDialog from '../components/AlertsDialog'
import CrearCuadreCaja from '../components/CrearCuadreCaja'
import { getRegistrosForHistoricoByIdCuadre } from '../services/registroDiarioServices'
import { FormProvider, useForm } from 'react-hook-form'

const HistoricosCuadreCaja = () => {

  const [cuadreSelected, setCuadreSelected] = useState({})
  const [mediosDePago, setMediosDePago] = useState([])
  const [listHistoricoCuadres, setListHistoricoCuadres] = useState([])
  const [fechaInicio, setFechaInicio] = useState(moment().startOf('month').format('YYYY-MM-DD'))
  const [fechaFin, setFechaFin] = useState(moment().endOf('month').format('YYYY-MM-DD'))
  const [valoresTotales, setValoresTotales] = useState(0)
  const [detalleCuadreSelected, setDetalleCuadreSelected] = useState([])
  const { isOpen: isOpenCuadreSelected, onOpen: onOpenCuadreSelected, onClose: onCloseCuadreSelected } = useDisclosure()
  const formMethodsCuadrarCaja = useForm()

  // Metodo para deseleccionar el cuadre de caja
  const handleDeselectCuadreCaja = () => {
    setCuadreSelected({})
    onCloseCuadreSelected()
  }

  // Metodo para seleccionar y ver el cuadre de caja correspondiente
  const handleSelectCuadreCaja = async (itemSelected) => {
    setCuadreSelected(itemSelected)
    const data = await getRegistrosForHistoricoByIdCuadre(itemSelected.id)
    if (data.length !== 0) {
      const {
        mediosDePago,
        datos
      } = data

      let registros = []
      let valoresTotales = {}
      mediosDePago.forEach(medioDePago => {
        datos.forEach(item => {
          let detalleFiltered = item.detalle.filter(itemDetalle => itemDetalle.metodoPago === medioDePago)
          if (detalleFiltered) {
            detalleFiltered.map(register => {
              registros.push(register)
            })
          }
        })
        valoresTotales = { ...valoresTotales, [`${medioDePago}`]: Number(registros.filter(x => x.metodoPago === medioDePago).reduce((total, item) => total + item.valorPagado, 0).toFixed(2)) }
        //list.push({ [`${medioDePago}`]: registros })
      })
      formMethodsCuadrarCaja.setValue('valorCajaReal', itemSelected.valorCuadreReal)
      setDetalleCuadreSelected(registros)
      setMediosDePago(mediosDePago)
      setValoresTotales(valoresTotales)
      onOpenCuadreSelected()
    }
  }

  // Metodo para obtener los registros x fecha
  const getHistoricoCuadres = async () => {
    const data = await getHistoricoCuadresByFecha(fechaInicio, fechaFin)
    if (data.length !== 0) {
      setListHistoricoCuadres(data)
    }
  }
  //Efecto para cargar los ingresos del dia
  useEffect(() => {
    async function obtenerDatos() {
      await getHistoricoCuadres()
    }
    obtenerDatos()
  }, [])

  return (
    <Flex flexDir={'column'} justify={'center'}>
      <Flex>
        <Heading as='h2' fontWeight={'bold'}>Historico de Cuadres de Caja</Heading>
      </Flex>
      <Flex flexDir={{ base: 'column', md: 'row' }} gap={8} my={5}>
        <Flex w={{ base: '100%', md: '30%' }} flexDir={'column'}
        >
          Fecha Inicio
          <Input
            size="md"
            type="date"
            bg="white"
            value={fechaInicio}
            onChange={(e) => setFechaInicio(e.target.value)}
          />
        </Flex>
        <Flex w={{ base: '100%', md: '30%' }} flexDir={'column'}
        >
          Fecha Fin
          <Input
            size="md"
            type="date"
            bg="white"
            value={fechaFin}
            onChange={(e) => setFechaFin(e.target.value)}
          />
        </Flex>
        <Flex alignSelf={{ md: 'end' }} w={{ base: '100%', md: '30%' }}>
          <Button w={'100%'} colorScheme={'blackSoft'} onClick={() => getHistoricoCuadres()}>Buscar</Button>
        </Flex>
      </Flex>
      <Divider my={3} borderColor={'black'} opacity={1} border={'2%'} />
      <Flex align={'center'} justify={'space-between'}>
        <Text fontSize={'20px'}>Fecha del Cuadre:</Text>
        <Text fontSize={'25px'} fontWeight={'semibold'}></Text>
      </Flex>
      <Flex mb={2} mt={1} flexDir={'column'} gap={2} w={'100%'} overflowX={'auto'}>
        <Table
          bgColor={'white'}
          borderRadius={10}
          boxShadow={'base'}
          w={'100%'}
          size={'sm'}
        >
          <Thead>
            <Tr>
              <Th>FECHA</Th>
              <Th isNumeric>EFECTIVO</Th>
              <Th isNumeric>TARJETA</Th>
              <Th isNumeric>IVA TARJETA 21%</Th>
              <Th isNumeric>VALOR TOTAL</Th>
              <Th>ACCION</Th>
            </Tr>
          </Thead>
          <Tbody>
            {listHistoricoCuadres.map((item, index) =>
              <Tr key={index}>
                <Td>{item.fecha}</Td>
                <Td isNumeric>{currencyFormat(item.valorEfectivo)}</Td>
                <Td isNumeric>{currencyFormat(item.valorTarjeta)}</Td>
                <Td isNumeric>{currencyFormat((item.valorTarjeta / 1.21) * 0.21)}</Td>
                <Td isNumeric>{currencyFormat(item.valorEfectivo + item.valorTarjeta)}</Td>
                <Td>
                  <Button variant={'solid'} colorScheme={'green'} w={'100%'} onClick={() => handleSelectCuadreCaja(item)} >Ver</Button>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Flex>
      <AlertsDialog
        titulo={`Cuadre de Caja del ${cuadreSelected.fecha}`}
        modalSize={'3xl'}
        isOpen={isOpenCuadreSelected}
        onClose={handleDeselectCuadreCaja}
        handleEvent={handleDeselectCuadreCaja}
      >
        <FormProvider {...formMethodsCuadrarCaja}>
          <CrearCuadreCaja
            titulo={'Registro Cuadre de Caja'}
            fechaCuadre={cuadreSelected.fecha}
            mediosDePago={mediosDePago}
            listACuadrar={detalleCuadreSelected}
            valoresTotales={valoresTotales}
            baseInicial={cuadreSelected.valorBaseInicial}
            hideCuadresPendientes
            readOnly
          />
        </FormProvider>
      </AlertsDialog>
    </Flex>
  )
}

export default HistoricosCuadreCaja