import { Box, Button, Flex, Icon, List, ListIcon, ListItem, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Portal } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MdNotificationImportant } from 'react-icons/md'
import { BiLike } from 'react-icons/bi'
import { getFechasCuadresPendientes } from '../services/registroDiarioServices'
import { groupBy } from '../utils/functions'

const NotificacionCuadrePendiente = ({ handleFecha }) => {

  const [listResult, setListResult] = useState({})

  // Metodo para obtener las fechas que no se ha realizado el cuadre de caja
  const getData = async () => {
    const data = await getFechasCuadresPendientes()
    if (data.length !== 0) {
      let dataGrouped = groupBy(data, 'fecha')
      setListResult(dataGrouped)
    }
  }
  //Efecto para cargar datos
  useEffect(() => {
    async function obtenerDatos() {
      await getData()
    }
    obtenerDatos()
  }, [])
  return (
    <Popover>
      <PopoverTrigger>
        <Button rightIcon={Object.keys(listResult).length ? <MdNotificationImportant /> : null} colorScheme={Object.keys(listResult).length ? 'red': 'gray'} variant='outline'>Cuadres Pendientes</Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>
            {Object.keys(listResult).length ? 'Tienes' : 'Sin'} cuadres pendientes
          </PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody>
            {Object.keys(listResult).length
              ?
              <List spacing={3}>
                {Object.keys(listResult).map((item, index) => (
                  <ListItem key={index} display='flex' justifyContent={'space-between'}>
                    <Box>
                      <ListIcon as={MdNotificationImportant} color='orange.500' />
                                        Fecha {item}
                    </Box>
                    <Button size={'sm'} colorScheme='green' onClick={async () => await handleFecha({ fechaCuadreSelected: new Date(item) })} >Ver</Button>
                  </ListItem>
                ))}
              </List>
              :
              <Flex justifyContent={'center'}>
                <Icon as ={BiLike} color={'green.500'} fontSize={'5xl'} ></Icon>
              </Flex>
            }
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export default NotificacionCuadrePendiente