import React from 'react'
import { Flex } from '@chakra-ui/react'

const Content = ({ children }) => {
  return (
    <Flex m={2} as="main">
      {children}
    </Flex>
  )
}

export default Content