import React, { useEffect, useState } from 'react'
import { Flex, Box, Button, useDisclosure, FormControl, FormLabel, Stack, Text, SimpleGrid, Divider } from '@chakra-ui/react'
import GastosVariables from '../containers/GastosVariables'
import GastosFijos from '../containers/GastosFijos'
import { deleteRegistroGasto, getAllGastosByFechas, getCategoriaGastos, getInitDataGastos, saveGasto } from '../services/gastosServices'
import ModalDialog from '../components/ModalDialog'
import { FormProvider, useForm } from 'react-hook-form'
import FormAddGastos from '../containers/FormAddGastos'
import AlertsDialog from '../components/AlertsDialog'
import { ShowToast } from '../components/Toast'
import DatePicker from 'react-datepicker'
import { registerLocale } from 'react-datepicker'
import es from 'date-fns/locale/es'
registerLocale('es', es)
import moment from 'moment'
import { ESTADO_GASTO, TIPO_GASTO } from '../utils/constantes'
import { Timestamp } from 'firebase/firestore'
import KPI from '../components/KPI'
import { currencyFormat, getDataIntervalos } from '../utils/functions'
import { GiCheckMark, GiExpense, GiPayMoney } from 'react-icons/gi'
import { MdAdd } from 'react-icons/md'

const Gastos = () => {
  const formData = useForm()

  const [fechaInicio, setFechaInicio] = useState(new Date(moment().startOf('month')))
  const [fechaFin, setFechafin] = useState(new Date(moment().endOf('month')))
  const [listInitData, setListInitData] = useState({})
  const [itemSelectedToDelete, setItemSelectedToDelete] = useState({})
  const [listGastosFijos, setListGastosFijos] = useState([])
  const [listGastosVariables, setListGastosVariables] = useState([])
  const { isOpen: isOpenAddNewGasto, onOpen: onOpenAddNewGasto, onClose: onCloseAddNewGasto } = useDisclosure()
  const { isOpen: isOpenAlertDeleteRegistro, onOpen: onOpenAlertDeleteRegistro, onClose: onCloseAlertDeleteRegistro } = useDisclosure()
  const [isSearchingInfo, setIsSearchingInfo] = useState(false)


  const handleSaveGasto = async (data) => {
    try {
      const accion = !data.id ? 'create' : 'update'
      let newGasto
      if (accion === 'create') {
        newGasto = {
          ...data,
          fechaLog: moment().format('YYYY-MM-DD'),
          fechaGasto: Timestamp.fromDate(data.fechaGasto),
          datosPeriodos: await getDataIntervalos(data),
          isActivo: true
        }
      } else {
        if (itemSelectedToDelete.tipoGasto === data.tipoGasto) {

          newGasto = {
            ...data,
            fechaLog: moment().format('YYYY-MM-DD'),
            fechaGasto: Timestamp.fromDate(data.fechaGasto),
            isActivo: true
          }
        } else {
          throw 'No puedes cambiar el tipo de gasto, debes eliminarlo y volverlo a crear.'
        }
      }

      let idRegistro = await saveGasto(newGasto)

      if (accion === 'create') {
        //save
        if (newGasto.tipoGasto === TIPO_GASTO.FIJO) {
          setListGastosFijos(data => [...data, { id: idRegistro, ...newGasto }])
        } else {
          setListGastosVariables(data => [...data, { id: idRegistro, ...newGasto }])
        }
      } else {
        if (newGasto.tipoGasto === TIPO_GASTO.FIJO) {
          const listFijosTemp = listGastosFijos.filter(item => item.id !== newGasto.id)
          setListGastosFijos([...listFijosTemp, newGasto])
        } else {
          const listVariablesTemp = listGastosVariables.filter(item => item.id !== newGasto.id)
          setListGastosVariables([...listVariablesTemp, newGasto])
        }

      }
      formData.reset()
      onCloseAddNewGasto()
      setItemSelectedToDelete({})
      ShowToast('Registro Guardado', '', 'success')
    } catch (error) {
      ShowToast('Atención', error, 'error')
    }
  }


  const handleEditGasto = async (dataEdit) => {
    setItemSelectedToDelete(dataEdit)
    formData.reset()
    formData.setValue('id', dataEdit.id)
    formData.setValue('categoriaGasto', dataEdit.categoriaGasto)
    formData.setValue('tipoGasto', dataEdit.tipoGasto)
    formData.setValue('fechaGasto', new Date(dataEdit.fechaGasto.toDate()))
    if (dataEdit.tipoGasto === TIPO_GASTO.FIJO) {
      formData.setValue('periodicidad', dataEdit.periodicidad)
      formData.setValue('duracionIntervalo', dataEdit.duracionIntervalo)
      formData.setValue('datosPeriodos', dataEdit.datosPeriodos)
    }
    formData.setValue('subtotal', dataEdit.subtotal)
    formData.setValue('IVA', dataEdit.IVA)
    formData.setValue('total', dataEdit.total)
    formData.setValue('pctjeIVA', dataEdit.pctjeIVA)
    formData.setValue('medioPago', dataEdit.medioPago)
    formData.setValue('estadoGasto', dataEdit.estadoGasto)
    formData.setValue('nota', dataEdit.nota)
    onOpenAddNewGasto()
  }

  const handleDeleteGasto = async (dataToDelete) => {
    setItemSelectedToDelete(dataToDelete)
    onOpenAlertDeleteRegistro()
  }

  // Confirmar eliminacion
  const handleConfirmDelete = async () => {

    await deleteRegistroGasto(itemSelectedToDelete.id)

    setItemSelectedToDelete({})

    if (itemSelectedToDelete.tipoGasto === TIPO_GASTO.FIJO) {
      setListGastosFijos(prev => prev.filter(item => item.id !== itemSelectedToDelete.id))
    } else {
      setListGastosVariables(prev => prev.filter(item => item.id !== itemSelectedToDelete.id))
    }

    onCloseAlertDeleteRegistro()

    ShowToast('Registro Eliminado', '', 'success')

  }
  const handleOpenNewGasto = () => {
    formData.reset()
    onOpenAddNewGasto()
  }


  // Metodo para cargar los empleados/barberos
  const getInitData = async () => {
    const result = await getInitDataGastos()
    const categorias = await getCategoriaGastos()
    if (result.length !== 0) {
      setListInitData({ ...result.data(), ...categorias.data() })
    }
  }

  const getGastosByFechas = async (startDate, endDate) => {
    setIsSearchingInfo(true)
    const data = await getAllGastosByFechas(startDate, endDate)
    if (data.length !== 0) {
      setListGastosVariables(data.filter(item => item.tipoGasto === TIPO_GASTO.VARIABLE))
      setListGastosFijos(data.filter(item => item.tipoGasto === TIPO_GASTO.FIJO))
    } else {
      setListGastosVariables([])
      setListGastosFijos([])
    }
    setIsSearchingInfo(false)
    console.log(data)
  }

  const getTotalGastosByEstado = (estado) => {
    if (estado) {
      const fullData = [
        ...listGastosVariables.filter(item => item.estadoGasto === estado),
        ...listGastosFijos.filter(item => item.estadoGasto === estado),
      ]
      return currencyFormat(Number(fullData.reduce((acc, item) => acc + (item.subtotal + item.IVA), 0)))
    } else {
      const fullData = [
        ...listGastosVariables,
        ...listGastosFijos,
      ]
      return currencyFormat(Number(fullData.reduce((acc, item) => acc + (item.subtotal + item.IVA), 0)))
    }
  }

  //Efecto para cargar los datos iniciales del formulario
  useEffect(() => {
    async function obtenerDatos() {
      let startDate = (moment().startOf('month').format('YYYY-MM-DD'))
      let endDate = (moment().endOf('month').format('YYYY-MM-DD'))
      await getGastosByFechas(startDate, endDate)
      await getInitData()
    }
    obtenerDatos()
  }, [])

  return (
    <>
      <Flex w={'100%'}>
        <Box className='jmz-paper' height={['100%', '100vh']} >
          <div className='jmz-card-header'>
            <div className='jmz-card-title'>
              Gastos
            </div>
          </div>
          <Flex flexDir={'column'} p={3} gap={6} mt={2} mb={6}>
            <Flex flexDir={'row-reverse'}>
              <Button leftIcon={<MdAdd />} colorScheme={'blackSoft'} onClick={() => handleOpenNewGasto()}>Nuevo Gasto</Button>
            </Flex>
            <Flex flexDir={'column'} align={'center'} gap={3}>
              <Text fontWeight={'semibold'}>Estadisticas de la búsqueda actual</Text>
              <Stack direction={{ base: 'column', lg: 'row' }} flexWrap='wrap' gap={4} width={'100%'} justifyContent={'center'}>
                <KPI title={'Gastos Pagados'} value={getTotalGastosByEstado(ESTADO_GASTO.PAGADO)} icon={GiCheckMark} />
                <KPI title={'Gastos Pendientes'} value={getTotalGastosByEstado(ESTADO_GASTO.PENDIENTE)} icon={GiPayMoney} />
                <KPI title={'Gastos Total Mes'} value={getTotalGastosByEstado()} icon={GiExpense} />
              </Stack>
            </Flex>
            <Flex flexDir={['column', 'row']} gap={5} mx={2} my={3} w={'auto'}>
              <FormControl id="fechaGasto">
                <FormLabel htmlFor='fechaGasto' fontWeight={'semibold'}>Fecha Inicio</FormLabel>
                <DatePicker
                  className='jmz-datepicker'
                  onChange={(e) => setFechaInicio(e)}
                  selected={fechaInicio}
                  selectsStart
                  startDate={fechaInicio}
                  dateFormat='dd/MM/yyyy'
                  isClearable
                  placeholderText='dd/MM/yyyy'
                  locale={'es'}
                />
              </FormControl>
              <FormControl id="fechaFinGasto">
                <FormLabel htmlFor='fechaFinGasto' fontWeight={'semibold'}>Fecha Fin</FormLabel>
                <DatePicker
                  className='jmz-datepicker'
                  onChange={(e) => setFechafin(e)}
                  selected={fechaFin}
                  selectsEnd
                  startDate={fechaInicio}
                  endDate={fechaFin}
                  minDate={fechaInicio}
                  dateFormat='dd/MM/yyyy'
                  isClearable
                  placeholderText='dd/MM/yyyy'
                  locale={'es'}
                />
              </FormControl>
              <Button colorScheme={'blackSoft'} w={{ base: 'full', lg: '2xl' }} onClick={async () => await getGastosByFechas(moment(fechaInicio).format('YYYY-MM-DD'), moment(fechaFin).format('YYYY-MM-DD'))} alignSelf={'end'}>Buscar</Button>
            </Flex>
            <SimpleGrid columns={1}>
              <Flex flexDir={'column'} mx={2} gap={3}>
                <Text fontSize='xl' fontWeight={'bold'} >Gastos Variables</Text>
                <GastosVariables
                  listGastosVariables={listGastosVariables}
                  handleEditGasto={handleEditGasto}
                  handleDeleteGasto={handleDeleteGasto}
                  isSearching={isSearchingInfo}
                />
              </Flex>
              <Divider my={6} />
              <Flex flexDir={'column'} mx={2} gap={3}>
                <Text fontSize='xl' fontWeight={'bold'} >Gastos Fijos</Text>
                <GastosFijos
                  listGastosFijos={listGastosFijos}
                  handleEditGasto={handleEditGasto}
                  handleDeleteGasto={handleDeleteGasto}
                  isSearching={isSearchingInfo}
                />
              </Flex>
            </SimpleGrid>

            <ModalDialog
              textTitleModal={'Gestion Gastos'}
              isOpen={isOpenAddNewGasto}
              onClose={onCloseAddNewGasto}
              modalSize={'xl'}
            >
              <FormProvider {...formData} >
                <FormAddGastos onSubmit={handleSaveGasto}
                  listInitData={listInitData}
                />
              </FormProvider>
            </ModalDialog>
            <AlertsDialog
              titulo={'Eliminar Gasto'}
              isOpen={isOpenAlertDeleteRegistro}
              onClose={onCloseAlertDeleteRegistro}
              handleEvent={handleConfirmDelete}
              showCancelButton
              colorOkBtn='red'
            >
              ¿Seguro que desea elminar este gasto? No se podra recuperar la información y afectara a todos los informes de gastos.
            </AlertsDialog>
          </Flex>
        </Box>

      </Flex>
    </>
  )
}

export default Gastos