import { Avatar, Flex, Heading } from '@chakra-ui/react'
import React from 'react'

const EmployeeCard = ({ employee, children }) => {
  return(
    <Flex gap={5}>
      <Flex>
        <Avatar size={'xl'} name={employee.empleado} src={`/images/${employee.empleado}.jpg`} />
      </Flex>
      <Flex flexDir={'column'} alignSelf={'center'}>
        <Heading as={'h4'} fontWeight={'bold'} >{employee.empleado}</Heading>
        {children}
      </Flex>
    </Flex>
  )
}

export default EmployeeCard