import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from '../firebase/firebase'

let collectionUsersRef = collection(db, 'users')

export const getUserDataByUid = async (uid) => {
  const queryRef = query(collectionUsersRef,
    where('uid', '==', uid))
  const resultRef = await getDocs(queryRef)
  if (resultRef.docs.length === 1) {
    let data = {}
    resultRef.docs.forEach((doc) => {
      data = {
        id: doc.id,
        ...doc.data()
      }
    })
    return data
  } else {
    return null
  }
}