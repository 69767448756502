import React, { useEffect, useState } from 'react'
import { Button, FormControl, FormErrorMessage, FormLabel, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import OptionBarbero from './OptionBarbero'
import { FaChevronDown } from 'react-icons/fa'
import { getEmpleadosAsync } from '../services/registroDiarioServices'
import { useFormContext } from 'react-hook-form'

const MenuBarberos = () => {
  //Variables
  const [initData, setInitData] = useState([])
  const [barberoSelected, setBarberoSelected] = useState('')

  // Declarando el useFormContext
  const {
    setValue,
    register,
    clearErrors,
    reset,
    formState: { errors, isSubmitSuccessful }
  } = useFormContext()

  // Metodo para cargar los empleados/barberos
  const getListEmpleados = async () => {
    const result = await getEmpleadosAsync()
    if (result.length !== 0) {
      setInitData(result.data().empleados)
    }
  }

  //Efecto para cargar el listado de barberos
  useEffect(() => {
    async function obtenerDatos() {
      await getListEmpleados()
    }
    obtenerDatos()
  }, [])

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
      setBarberoSelected('')
    }
  }, [isSubmitSuccessful, reset])

  return(

    <FormControl id="empleado" isInvalid={errors.empleado} w='100%'>
      <FormLabel htmlFor='empleado' fontWeight={'semibold'}>Barbero</FormLabel>
      <Menu matchWidth>
        <MenuButton w={'100%'} py={10} as={Button} rightIcon={<FaChevronDown />}>
          { barberoSelected ?
            <OptionBarbero empleado={barberoSelected} />
            :
            <>
                  Selecciona un barbero
            </>
          }
        </MenuButton>
        <MenuList  {...register('empleado', { required: 'Selecciona un barbero' })} >
          {initData.map((option, index) => (
            <MenuItem minH='48px' key={index} onClick={() =>  { setBarberoSelected(option); setValue('empleado',option); clearErrors('empleado') }}>
              <OptionBarbero empleado={option}  />
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <FormErrorMessage>
        {errors.empleado && errors.empleado.message}
      </FormErrorMessage>
    </FormControl>
  )
}

export default MenuBarberos