import { Td, Tr } from '@chakra-ui/react'
import React from 'react'
import { currencyFormat } from '../utils/functions'

const ItemRowCuadreCaja = ({ value }) => {
  return (
    <Tr>
      <Td>{value.empleado}</Td>
      <Td>{value.cliente}</Td>
      <Td isNumeric>{currencyFormat(value.valorPagado)}</Td>
    </Tr>
  )
}

export default ItemRowCuadreCaja