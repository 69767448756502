import { createStandaloneToast } from '@chakra-ui/react'

const { toast } = createStandaloneToast()

export function ShowToast(title, message, status) {
  toast({
    title: title,
    description: message,
    status: status,
    duration: 5000,
    isClosable: true
  })
}