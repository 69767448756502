import { Td } from '@chakra-ui/table'
import React from 'react'
import { currencyFormat } from '../utils/functions'

const ContentTableRegistroMensual = ({ data, handleOnClickSelectRegistro }) => {

  if(data){
    return(
      <>
        <Td borderLeft={'1px solid #0000001e'} isNumeric>{data[0].detalle.length}</Td>
        <Td isNumeric>{currencyFormat(data[0].detalle.filter(x => x.metodoPago === 'Efectivo').reduce((total, item) => total + item.valorPagado, 0))}</Td>
        <Td isNumeric>{currencyFormat(data[0].detalle.filter(x => x.metodoPago === 'Tarjeta').reduce((total, item) => total + item.valorPagado, 0))}</Td>
        <Td borderRight={'1px solid #0000001e'} isNumeric fontWeight={'bold'} _hover={{ cursor : 'pointer' }} onClick={() => handleOnClickSelectRegistro(data[0])}>{currencyFormat(data[0].detalle.reduce((total, item) => total + item.valorPagado, 0))}</Td>
      </>
    )
  }else{
    return(
      <>
        <Td borderLeft={'1px solid #0000001e'} isNumeric>-</Td>
        <Td isNumeric>-</Td>
        <Td isNumeric>-</Td>
        <Td borderRight={'1px solid #0000001e'} isNumeric fontWeight={'bold'}>-</Td>
      </>
    )
  }
}

export default ContentTableRegistroMensual