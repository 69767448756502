import { Alert, AlertIcon, Box, Button, Container, Divider, Flex, FormControl, FormErrorMessage, Heading, Input, InputGroup, InputRightElement, Table, Tbody, Td, Text, Tfoot, Th, Thead, Tr } from '@chakra-ui/react'
import moment from 'moment/moment'
import React from 'react'

import { useFormContext } from 'react-hook-form'
import { FaEuroSign } from 'react-icons/fa'
import { RiSave3Fill } from 'react-icons/ri'
import { currencyFormat } from '../utils/functions'
import ItemRowCuadreCaja from './ItemRowCuadreCaja'
import NotificacionCuadrePendiente from './NotificacionCuadrePendiente'
const CrearCuadreCaja = ({
  titulo,
  fechaCuadre = moment().format('YYYY-MM-DD'),
  mediosDePago,
  listACuadrar,
  handleSaveCuadreCaja,
  handleSearchCuadreCaja,
  valoresTotales,
  baseInicial = 100,
  handleEditBaseInicial,
  readOnly = false,
  hideCuadresPendientes = false,
  showResults = listACuadrar.length !== 0 ? true : false
}) => {

  // Declarando el useFormContext
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting }
  } = useFormContext({ mode: 'onBlur' })



  const valorTotal = Object.values(valoresTotales).reduce((total, value) => (total + value), 0)

  return (
    <Flex flexDir={'column'} justify={'center'}>
      <Flex gap={5}>
        <Heading as='h2' fontWeight={'bold'}>{titulo}</Heading>
        {!hideCuadresPendientes &&
          <NotificacionCuadrePendiente handleFecha={handleSearchCuadreCaja} />
        }
      </Flex>
      <Divider my={3} borderColor={'black'} opacity={1} border={'2%'} />
      <Flex align={'center'} justify={'space-between'}>
        <Text fontSize={'20px'}>Fecha del Cuadre:</Text>
        <Text fontSize={'25px'} fontWeight={'semibold'}>{fechaCuadre}</Text>
      </Flex>
      {showResults
        ?
        <>
          <Flex flexDir={['column', 'row']} justify={'space-between'} gap={6} >

            {mediosDePago.map((element, index) => {
              return (
                <Flex flexDir={'column'} key={index} gap={2} px={2} w={'100%'}>
                  <Heading as={'h3'}>{element}</Heading>
                  <Flex mb={2} mt={1} flexDir={'column'}
                    maxHeight={'700px'} overflowY={'auto'}>
                    <Table
                      bgColor={'white'}
                      borderRadius={10}
                      boxShadow={'base'}
                      w={'100%'}
                      size={'sm'}
                    >
                      <Thead>
                        <Tr>
                          <Th>EMPLEADO</Th>
                          <Th>CLIENTE</Th>
                          <Th isNumeric>VALOR PAGADO</Th>
                        </Tr>
                      </Thead>
                      {listACuadrar.filter(items => items.metodoPago === element).map((item, indexKey) => {
                        return (
                          <Tbody key={indexKey}>
                            <ItemRowCuadreCaja key={index} value={item} />
                          </Tbody>
                        )
                      })}
                    </Table>
                  </Flex>
                </Flex>
              )
            })}
          </Flex>
          <Divider my={7} borderColor={'black'} opacity={1} border={'2%'} />
          <form key={2} onSubmit={handleSubmit(handleSaveCuadreCaja)}>
            <Container maxW={['100%', 'container.md']}>

              <Flex mb={2} mt={1} flexDir={'column'} >
                <Table
                  bgColor={'white'}
                  borderRadius={10}
                  boxShadow={'base'}
                >
                  <Thead>
                    <Tr>
                      <Th>MEDIO DE PAGO</Th>
                      <Th isNumeric>TOTAL</Th>
                    </Tr>
                  </Thead>
                  <Tbody fontWeight={'semibold'}>
                    <Tr>
                      <Td>Base inicial de caja</Td>
                      <Td isNumeric>
                        <Box>
                          {currencyFormat(baseInicial)}
                        </Box>
                        {!readOnly &&
                          <Box py={2}>
                            <Button w={['100%', '30%']} colorScheme='blackSoft' variant={'solid'} size={'xs'} aria-label='Editar' onClick={handleEditBaseInicial} >Editar</Button>
                          </Box>
                        }
                      </Td>
                    </Tr>
                    {Object.keys(valoresTotales).map((key, indexKey) => (
                      <Tr key={indexKey}>
                        <Td>{key}</Td>
                        <Td isNumeric>{currencyFormat(valoresTotales[key])}</Td>
                      </Tr>
                    ))
                    }
                    <Tr>
                      <Td>IVA Tarjeta 21%</Td>
                      <Td isNumeric>{currencyFormat((valoresTotales['Tarjeta'] / 1.21) * 0.21)}</Td>
                    </Tr>
                  </Tbody>
                  <Tfoot fontSize={'xl'} fontWeight={'bold'}>
                    <Tr>
                      <Td>TOTAL <small>(Base + Efectivo)</small></Td>
                      <Td textAlign={'right'}>{currencyFormat((valorTotal + baseInicial) - valoresTotales.Tarjeta)}</Td>
                    </Tr>
                  </Tfoot>
                </Table>
              </Flex>
              <Divider my={7} borderColor={'black'} opacity={1} border={'2%'} />
              <Flex
                mb={2}
                mt={1}
                p={5}
                flexDir={'column'}
                bgColor={'white'}
                borderRadius={10}
                boxShadow={'base'} gap={3} >
                <Flex w={'100%'}>Ingresa el valor caja (Base + Efectivo)</Flex>
                <Flex w={'100%'} flexDir={'column'} gap={3}>
                  <FormControl id="valorCajaReal" isInvalid={errors.valorCajaReal}>
                    <InputGroup>
                      <Input size={'lg'} defaultValue={0} readOnly={readOnly}
                        {...register('valorCajaReal', { required: 'Ingresa un valor', valueAsNumber: true })} />
                      <InputRightElement>
                        <FaEuroSign color={'green'} fontSize={'25px'} />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                      {errors.valorCajaReal && errors.valorCajaReal.message}
                    </FormErrorMessage>
                  </FormControl>
                </Flex>
                <Flex justifyContent={'space-between'} color={(((valorTotal + baseInicial) - valoresTotales.Tarjeta) === watch('valorCajaReal')) ? 'green' : 'red'}>
                  <Box>Diferencia</Box>
                  <Box>
                    {(valorTotal + baseInicial) - watch('valorCajaReal') ?
                      currencyFormat(watch('valorCajaReal') - ((valorTotal + baseInicial) - valoresTotales.Tarjeta))
                      :
                      0}
                  </Box>
                </Flex>
              </Flex>
              {!readOnly &&
                <Flex my={6} gap={5} flexDir={['column', 'row']} justifyContent='right'>
                  <Box>
                    <Button w="100%" colorScheme={'green'} isLoading={isSubmitting} type={'submit'} leftIcon={<RiSave3Fill />}>Realizar Cuadre de Caja</Button>
                  </Box>
                </Flex>
              }
            </Container>
          </form>
        </>
        :
        <Alert status='info' rounded={'10px'}>
          <AlertIcon />
          Aun no hay registros
        </Alert>
      }
    </Flex>

  )
}

export default CrearCuadreCaja