import React from 'react'
import { Flex, Text, Heading, Avatar, Button, Box, Tag, TagLeftIcon, TagLabel } from '@chakra-ui/react'
// import { currencyFormat } from '../utils/functions'
// import { TfiCreditCard } from 'react-icons/tfi'
import { SiVisa } from 'react-icons/si'
import { GiMoneyStack } from 'react-icons/gi'

const EmployeeItem = ({ item, onClick }) => {
  return (
    <Flex p={4}
      gap={4}
      flexDir={'column'}
      bgColor={'white'}
      borderRadius={10}
      className={'jmz-outline'}
      w={'100%'}>
      <Flex
        alignItems='center'
        justifyContent={'space-between'}
      >

        <Flex gap={2}>
          <Avatar size={'lg'} name={item.empleado} src={`/images/${item.empleado}.jpg`} />
          <Flex flexDir={'column'} justify={'center'}>
            <Heading as="h4" size="sm" fontWeight="bold">{item.empleado}</Heading>
            <Text>Cortes {item.detalle.length}</Text>
          </Flex>
        </Flex>
        {/* <Flex flexDir="column">
          <Text as={'div'}  fontSize='2xl' fontWeight='semibold'>
            {item ? currencyFormat(item.detalle.reduce((total, data) => total + data.valorPagado, 0)) : <CircularProgress size={'48px'} isIndeterminate color={'blackAlpha.600'} />}
          </Text>
        </Flex> */}
      </Flex>
      <Flex justify={'end'} gap={2}>
        <Box>
          <Tag size={'lg'} variant='subtle' colorScheme='blue'>
            <TagLeftIcon boxSize='30px' as={SiVisa} />
            {/* <TagLabel>{currencyFormat(item.detalle.filter(item => item.metodoPago === 'Tarjeta').reduce((total, data) => total + data.valorPagado, 0))}</TagLabel> */}
            <TagLabel>{item.detalle.filter(item => item.metodoPago === 'Tarjeta').length}</TagLabel>
          </Tag>
        </Box>
        <Box>
          <Tag size={'lg'} variant='subtle' colorScheme='green'>
            <TagLeftIcon boxSize='25px' as={GiMoneyStack} />
            {/* <TagLabel>{currencyFormat(item.detalle.filter(item => item.metodoPago === 'Efectivo').reduce((total, data) => total + data.valorPagado, 0))}</TagLabel> */}
            <TagLabel>{item.detalle.filter(item => item.metodoPago === 'Efectivo').length}</TagLabel>
          </Tag>
        </Box>
      </Flex>
      <Flex w={'100%'}>
        <Button w={'100%'} colorScheme={'blackSoft'} onClick={() => onClick(item)}>Ver Detalle</Button>
      </Flex>
    </Flex>

  )
}

export default EmployeeItem