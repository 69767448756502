import { IconButton, Table, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr } from '@chakra-ui/react'
import React from 'react'
import { MdOutlineSearch } from 'react-icons/md'
import { currencyFormat } from '../utils/functions'

const TableRegistroMensualXEmpleado = ({ data, handleOnClickSelectRegistro }) => {
  return (
    <TableContainer>
      <Table variant='simple' size={'sm'} className={'jmz-table'}>
        <Thead>
          <Tr>
            <Th>Fecha</Th>
            <Th>Cantidad</Th>
            <Th>Efectivo</Th>
            <Th>Tarjeta</Th>
            <Th>Total</Th>
            <Th>Accion</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={index} >
              <Td>{item.fecha}</Td>
              <Td isNumeric>{item.detalle.length}</Td>
              <Td isNumeric>{currencyFormat(item.detalle.filter(x => x.metodoPago === 'Efectivo').reduce((total, subItem) => total + subItem.valorPagado, 0))}</Td>
              <Td isNumeric>{currencyFormat(item.detalle.filter(x => x.metodoPago === 'Tarjeta').reduce((total, subItem) => total + subItem.valorPagado, 0))}</Td>
              <Td isNumeric>{currencyFormat(item.detalle.reduce((total, subItem) => total + subItem.valorPagado, 0))}</Td>
              <Td><IconButton colorScheme={'blackSoft'} fontSize='xl' onClick={() => handleOnClickSelectRegistro(item)} aria-label='Ver Registro' icon={<MdOutlineSearch />} /></Td>
            </Tr>
          ))}
        </Tbody>
        <Tfoot>
          <Tr>
            <Th>Total</Th>
            <Th isNumeric>{data.reduce((total, item) => total + item.detalle.length, 0)}</Th>
            <Th>{currencyFormat(data.reduce((total, item) => total + (item.detalle.filter(x => x.metodoPago === 'Efectivo').reduce((total, subItem) => total + subItem.valorPagado, 0)), 0))}</Th>
            <Th>{currencyFormat(data.reduce((total, item) => total + (item.detalle.filter(x => x.metodoPago === 'Tarjeta').reduce((total, subItem) => total + subItem.valorPagado, 0)), 0))}</Th>
            <Th>{currencyFormat(data.reduce((total, item) => total + (item.detalle.reduce((total, subItem) => total + subItem.valorPagado, 0)), 0))}</Th>
            <Th>Accion</Th>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>

  )
}

export default TableRegistroMensualXEmpleado