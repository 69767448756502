import { Stack } from '@chakra-ui/react'
import React from 'react'
import { BsCart3 } from 'react-icons/bs'
import { GiCash, GiMoneyStack } from 'react-icons/gi'
import { MdOutlineContentCut } from 'react-icons/md'
import { SiVisa } from 'react-icons/si'
import { currencyFormat } from '../utils/functions'
import KPI from './KPI'

const StatsMensuales = ({ listResult, isGlobal = false }) => {

  const getTotalByMedioPago = (data, medioPago) => {
    let total = 0
    if (medioPago) {

      data.forEach(item => {
        total += item.detalle.filter(x => x.metodoPago === medioPago).reduce((total, item) => total + item.valorPagado, 0)
      })
    } else {
      data.forEach(item => {
        total += item.detalle.reduce((total, item) => total + item.valorPagado, 0)
      })
    }
    return total
  }

  const getIVAValue = (data, medioPago) => {
    let valueIVA = 0
    data.forEach(item => {
      valueIVA += item.detalle.filter(x => x.metodoPago === medioPago).reduce((total, item) => total + item.valorPagado, 0)
    })
    valueIVA = (valueIVA / 1.21) * 0.21

    return valueIVA
  }

  const getTotalProductosVendidos = (data, empleado) => {
    return data.filter(x => x.empleado === empleado).reduce((total, item) => total + item.detalle.length, 0)
  }

  const getTotalCortes = (data, empleado) => {
    return data.filter(x => x.empleado !== empleado).reduce((total, item) => total + item.detalle.length, 0)
  }


  return (
    <>
      <Stack direction={{ base: 'column', lg: 'row' }} flexWrap='wrap' gap={4} width={'100%'} justifyContent={'center'}>
        {isGlobal &&
          <KPI title={'Total Productos'} value={getTotalProductosVendidos(listResult, 'PRODUCTOS')} icon={BsCart3} />
        }
        {(!isGlobal && listResult[0].empleado === 'PRODUCTOS')
          ?
          <KPI title={'Total Productos'} value={getTotalProductosVendidos(listResult, 'PRODUCTOS')} icon={BsCart3} />
          :
          <KPI title={'Total Cortes'} value={getTotalCortes(listResult, 'PRODUCTOS')} icon={MdOutlineContentCut} />
        }
        <KPI title={'Total Efectivo'} value={currencyFormat(getTotalByMedioPago(listResult, 'Efectivo'))} icon={GiMoneyStack} iconColor={'green'} />
        <KPI title={'Total Tarjeta'} value={currencyFormat(getTotalByMedioPago(listResult, 'Tarjeta'))} icon={SiVisa} iconColor={'darkBlue'}>
          21% IVA ({currencyFormat(getIVAValue(listResult, 'Tarjeta'))})
        </KPI>
        <KPI title={'Total Mensual'} value={currencyFormat(getTotalByMedioPago(listResult))} icon={GiCash} />
      </Stack>
    </>
  )
}

export default StatsMensuales