import React, { useEffect } from 'react'
import {
  Flex,
  FormControl,
  Input,
  FormLabel,
  FormErrorMessage,
  Button,
  Text,
  SimpleGrid,
  Image
}
  from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { getAuth, signInWithEmailAndPassword, setPersistence, browserLocalPersistence } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { getUserDataByUid } from '../services/UserServices'
import { ShowToast } from '../components/Toast'


const Login = ({ userAuth }) => {
  const navigate = useNavigate()

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm()

  async function logUser(response) {
    const userData = await getUserDataByUid(response.user.uid)
    if (userData) {
      await userAuth(userData)
      window.localStorage.setItem('Auth Token', response._tokenResponse.refreshToken)
      navigate('/registro-diario')
    } else {
      ShowToast('', 'Error en el login, contacte con el administrador', 'error')
      navigate('/login')
    }
    //sessionStorage.setItem('Auth Token', response._tokenResponse.refreshToken)
  }
  const onSubmit = (values) => {
    const authentication = getAuth()
    //sessionStorage.removeItem('Auth Token')
    setPersistence(authentication, browserLocalPersistence)
      .then(() => {
        return signInWithEmailAndPassword(authentication, values.usuario, values.password)
          .then((response) => {
            logUser(response)
          })
          .catch((error) => {
            console.log(error.code)
            switch (error.code) {
            case 'auth/wrong-password':
              ShowToast('', 'Password incorrecto', 'warning')
              break
            case 'auth/user-not-found':
              ShowToast('', 'Usuario no encontrado', 'warning')
              break
            case 'auth/invalid-email':
              ShowToast('', 'Email incorrecto', 'warning')
              break
            case 'auth/invalid-api-key':
              ShowToast('', 'API invalid key', 'error')
              break
            default:
              ShowToast('', error.code, 'error')
              break
            }
          })
      })
      .catch((error) => {
        ShowToast(error.code, error.message, 'error')
      })

  }

  useEffect(() => {
    let authToken = window.localStorage.getItem('Auth Token')
    console.log('El token es: ', authToken)
    if (authToken) {
      console.log('Token Existente')
      navigate('/inicio')
    }
  }, [])

  return (
    <>
      <Flex
        bg={'black'}
        h={'100vh'}
        flexDir={{ base: 'column',  lg:'row' }}
        justifyContent={'center'}
        alignItems={'center'}
        px={3}
        gap={5}
      >
        <Flex justifyContent={'center'} w={{ base: '100%', lg:'60%' }}>
          <Image width={['100%', '65%']} src='/images/logo_svg.svg' alt='Mr Champagne Barberia Logo' />
        </Flex>
        <Flex justifyContent={'center'}  w={{ base: '100%', lg: '40%' }}>
          <SimpleGrid columns={1} w={['100%','50%']}>
            <Flex
              bg={'white'}
              w={'100%'}
              direction={'column'}
              p={5}
              borderRadius={15}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <Flex
                  justifyContent={'center'}
                >
                  <Text fontWeight={'bold'} fontSize={'2xl'} fontFamily={'sans-serif'}>Iniciar Sesión</Text>
                </Flex>
                <Flex my={5}>
                  <FormControl variant="floating" id="usuario" isInvalid={errors.usuario}>
                    <FormLabel htmlFor='usuario'>Usuario</FormLabel>
                    <Input placeholder=" " size={'lg'} w={'100%'}
                      {...register('usuario', { required: 'Ingresa el usuario' })}
                    />
                    <FormErrorMessage>
                      {errors.usuario && errors.usuario.message}
                    </FormErrorMessage>
                  </FormControl>
                </Flex>
                <Flex my={5}>
                  <FormControl variant="floating" id="password" isInvalid={errors.password} >
                    <FormLabel htmlFor='password'>Password</FormLabel>
                    <Input placeholder=" " size={'lg'} type={'password'}
                      {...register('password', { required: 'Ingresa el password' })}
                    />
                    <FormErrorMessage>
                      {errors.password && errors.password.message}
                    </FormErrorMessage>
                  </FormControl>
                </Flex>
                <Button mt={4} w={'100%'} colorScheme='blackSoft' isLoading={isSubmitting} type='submit'>
                  Entrar
                </Button>
              </form>
            </Flex>
          </SimpleGrid>
        </Flex>
      </Flex>

    </>
  )
}

export default Login