import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { RiSearchLine } from 'react-icons/ri'
import DatePicker from 'react-datepicker'
import es from 'date-fns/locale/es'
import { registerLocale } from 'react-datepicker'
registerLocale('es', es)


const SearchCuadreCaja = ({ handleSearchCuadreCaja, fechaCuadre }) => {

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useFormContext({ mode: 'onBlur' })


  return(
    <form key={1} style={{ justifySelf: 'center' }}  onSubmit={handleSubmit(handleSearchCuadreCaja)}>
      <Flex flexDir={'column'} gap={4} justifyContent={'center'} alignItems={'center'}>
        <Box>
          <FormControl id="fechaCuadreSelected" isInvalid={errors.fechaCuadreSelected}>
            <FormLabel htmlFor='fechaCuadreSelected' fontWeight={'semibold'}>Fecha del Cuadre</FormLabel>
            <Controller
              control={control}
              name="fechaCuadreSelected"
              render={({ field: { onChange, onBlur, value = new Date(fechaCuadre) } }) => (
                <DatePicker
                  className='jmz-datepicker'
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  dateFormat='dd/MM/yyyy'
                  isClearable
                  placeholderText='dd/MM/yyyy'
                  locale={'es'}
                />
              )}
            />
            <FormErrorMessage>
              {errors.fechaCuadreSelected && errors.fechaCuadreSelected.message}
            </FormErrorMessage>
          </FormControl>
        </Box>
        <Flex>
          <Button w="100%" colorScheme={'blackSoft'} size='md' isLoading={isSubmitting} type={'submit'} leftIcon={<RiSearchLine />}>BUSCAR</Button>
        </Flex>
      </Flex>
    </form>
  )
}

export default SearchCuadreCaja