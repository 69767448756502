import { Button, Container, Flex, FormControl, FormErrorMessage, Input, InputGroup, InputRightElement, Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure } from '@chakra-ui/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FaEuroSign } from 'react-icons/fa'
import { FiSave } from 'react-icons/fi'
import AlertsDialog from '../components/AlertsDialog'
import CrearCuadreCaja from '../components/CrearCuadreCaja'
import HistoricosCuadreCaja from '../containers/HistoricosCuadreCaja'
import { getRegistrosForCuadreByFecha, saveCuadreCaja } from '../services/cuadreCajaServices'
import { ShowToast } from '../components/Toast'
import SearchCuadreCaja from '../components/SearchCuadreCaja'

const CuadreCaja = () => {

  const [listRegistrosACuadrar, setListRegistrosACuadrar] = useState([])
  const [metodoPago, setMetodoPago] = useState([])
  const [fullData, setFullData] = useState([])
  const [valoresTotales, setValoresTotales] = useState(0)
  const [valorBaseInicial, setValorBaseInicial] = useState(0)
  const [valorCuadreReal, setValorCuadreReal] = useState(0)
  const [fechaCuadreSelected, setFechaCuadreSelected] = useState(moment().format('YYYY-MM-DD'))
  const { isOpen: isOpenAlertConfirmCuadre, onOpen: onOpenAlertConfirmCuadre, onClose: onCloseAlertConfirmCuadre } = useDisclosure()
  const { isOpen: isOpenChangeBaseInicial, onOpen: onOpenChangeBaseInicial, onClose: onCloseChangeBaseInicial } = useDisclosure()

  const { register: registerBaseInicial, isSubmitting: submittingBaseInicial, handleSubmit: handleSubmitBaseInicial, formState: { errors } } = useForm()

  const formMethodsCuadrarCaja = useForm()
  const formMethodsSearchCuadreCaja = useForm()

  const handleOpenConfirmCuadreCaja = (value) => {
    onOpenAlertConfirmCuadre()
    setValorCuadreReal(value.valorCajaReal)
  }

  const handleConfirmChangeBaseInicial = (value) => {
    onCloseChangeBaseInicial()
    setValorBaseInicial(value.nuevoValorBaseInicial)
    ShowToast('Operación Realizada', 'Base Inicial modificada correctamente', 'success')
  }

  const handleSaveCuadreCaja = async () => {
    formMethodsCuadrarCaja.reset()
    const newData = {
      fecha: fechaCuadreSelected,
      fechaLog: moment().format('YYYY-MM-DD HH:mm'),
      usuarioLog: 'userAdmin',
      cantidadEfectivo: listRegistrosACuadrar.filter(item => item.metodoPago === 'Efectivo').length,
      valorEfectivo: valoresTotales.Efectivo,
      cantidadTarjeta: listRegistrosACuadrar.filter(item => item.metodoPago === 'Tarjeta').length,
      valorTarjeta: valoresTotales.Tarjeta,
      valorCuadreReal: valorCuadreReal,
      valorBaseInicial: valorBaseInicial,
      resultadoCuadre: valorCuadreReal - (valoresTotales.Efectivo + valoresTotales.Tarjeta + valorBaseInicial)
    }
    const idCuadre = await saveCuadreCaja(newData, fullData)

    setListRegistrosACuadrar(prev => prev.map(item => ({ ...item, idCuadreCaja: idCuadre })))

    onCloseAlertConfirmCuadre()

    ShowToast('Operación Realizada', 'Cuadre de caja realizado correctamente', 'success')

  }

  // Metodo para obtener los registros x fecha
  const getRegistroDeHoy = async (fecha) => {
    const data = await getRegistrosForCuadreByFecha(fecha)
    if (data.length !== 0) {
      const {
        mediosDePago,
        datos
      } = data

      let registros = []
      let valoresTotales = {}
      mediosDePago.forEach(medioDePago => {
        datos.forEach(item => {
          let detalleFiltered = item.detalle.filter(itemDetalle => itemDetalle.metodoPago === medioDePago)
          if (detalleFiltered) {
            detalleFiltered.map(register => {
              registros.push(register)
            })
          }
        })
        valoresTotales = { ...valoresTotales, [`${medioDePago}`]: Number(registros.filter(x => x.metodoPago === medioDePago).reduce((total, item) => total + item.valorPagado, 0).toFixed(2)) }
        //list.push({ [`${medioDePago}`]: registros })
      })
      setListRegistrosACuadrar(registros)
      setMetodoPago(mediosDePago)
      setFullData(datos)
      setValoresTotales(valoresTotales)
    }
  }

  const handleSearchCuadreCajaByFecha = async (data) => {
    setFechaCuadreSelected(moment(data.fechaCuadreSelected).format('YYYY-MM-DD'))
    await getRegistroDeHoy(moment(data.fechaCuadreSelected).format('YYYY-MM-DD'))
  }

  //Efecto para cargar los ingresos del dia
  useEffect(() => {
    async function obtenerDatos() {
      await getRegistroDeHoy(moment().format('YYYY-MM-DD'))
    }
    obtenerDatos()
  }, [])

  return (

    <Flex w={'100%'} >
      <div className='jmz-paper' >
        <div className='jmz-card-header'>
          <div className='jmz-card-title'>
            Cuadre de Caja
          </div>
        </div>
        <Flex p={3} gap={6} mt={2} mb={6}>
          <Tabs size='md' variant='enclosed-colored' colorScheme={'blackSoft'} w={'100%'}>
            <TabList>
              <Tab>Cuadre del dia</Tab>
              <Tab>Historico de Cuadres</Tab>
            </TabList>
            <TabPanels>
              <TabPanel px={0}>
                <FormProvider  {...formMethodsSearchCuadreCaja} >
                  <SearchCuadreCaja
                    handleSearchCuadreCaja={handleSearchCuadreCajaByFecha}
                    fechaCuadre={fechaCuadreSelected}
                  />
                </FormProvider>
                <FormProvider {...formMethodsCuadrarCaja} >
                  <CrearCuadreCaja
                    titulo={'Realizar Cuadre de Caja Diario'}
                    mediosDePago={metodoPago}
                    listACuadrar={listRegistrosACuadrar}
                    valoresTotales={valoresTotales}
                    baseInicial={valorBaseInicial}
                    handleEditBaseInicial={onOpenChangeBaseInicial}
                    handleSaveCuadreCaja={handleOpenConfirmCuadreCaja}
                    handleSearchCuadreCaja={handleSearchCuadreCajaByFecha}
                    fechaCuadre={fechaCuadreSelected}
                  />
                </FormProvider>

              </TabPanel>
              <TabPanel px={0}>
                <Container maxW={['100%', 'container.md']}>
                  <HistoricosCuadreCaja />
                </Container>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </div>
      <AlertsDialog
        titulo={'Confirmar Cuadre de Caja'}
        isOpen={isOpenAlertConfirmCuadre}
        onClose={onCloseAlertConfirmCuadre}
        handleEvent={handleSaveCuadreCaja}
        showCancelButton
      >
        Pulsa Aceptar para confirmar el cuadre de caja
      </AlertsDialog>
      <AlertsDialog
        titulo={'Editar Valor de Base Inicial'}
        isOpen={isOpenChangeBaseInicial}
        onClose={onCloseChangeBaseInicial}
        handleEvent={onCloseChangeBaseInicial}
      >
        <form onSubmit={handleSubmitBaseInicial(handleConfirmChangeBaseInicial)}>
          <Flex flexDir={'column'} gap={3}>
            <Flex w={'100%'}>Ingresa el nuevo importe de la base inicial</Flex>
            <Flex w={'100%'} flexDir={'column'} gap={3}>
              <FormControl id="valorBaseInicial" isInvalid={errors.nuevoValorBaseInicial}>
                <InputGroup>
                  <Input placeholder=" " size={'lg'}
                    {...registerBaseInicial('nuevoValorBaseInicial', { required: 'Ingresa un valor', valueAsNumber: true })} />
                  <InputRightElement>
                    <FaEuroSign color={'green'} fontSize={'25px'} />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  {errors.nuevoValorBaseInicial && errors.nuevoValorBaseInicial.message}
                </FormErrorMessage>
              </FormControl>
              <Button w="100%" colorScheme={'blackSoft'} isLoading={submittingBaseInicial} type={'submit'} leftIcon={<FiSave />}>GUARDAR</Button>
            </Flex>
          </Flex>
        </form>
      </AlertsDialog>
    </Flex>
  )
}

export default CuadreCaja