import React from 'react'
import { Alert, AlertIcon, Flex, Spinner } from '@chakra-ui/react'

import TableGastosVariables from '../components/TableGastosVariables'

const GastosVariables = ({ listGastosVariables, handleEditGasto, handleDeleteGasto, isSearching }) => {


  return (
    <Flex flexDir={'column'} gap={5} >
      <Flex gap={5} w={'100%'}>
        {isSearching ?
          <Flex justifyContent={'center'} w='100%'>
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blackSoft'
              size='xl'
            />
          </Flex>
          :
          listGastosVariables.length > 0 ?
            <TableGastosVariables
              data={listGastosVariables}
              handleEditGasto={handleEditGasto}
              handleDeleteGasto={handleDeleteGasto}
            />
            :
            <Alert status='info' rounded={'10px'}>
              <AlertIcon />
              No hay registros para el mes seleccionado
            </Alert>
        }

      </Flex>
    </Flex>
  )
}

export default GastosVariables