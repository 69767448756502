import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import React from 'react'
import DatePicker from 'react-datepicker'
import { registerLocale } from 'react-datepicker'
import es from 'date-fns/locale/es'
registerLocale('es', es)
import 'react-datepicker/dist/react-datepicker.css'
import { Controller, useFormContext } from 'react-hook-form'
import MenuBarberos from '../components/MenuBarberos'



const FormGetRegistroMensual = ({ onSubmit, showEmpleado = false }) => {
  // Declarando el useFormContext
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors }
  } = useFormContext()
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex flexDir={'column'} gap={4}>
        <Flex flexDir={['column', 'row']} gap='24px'>
          <Box w='300px'>
            <FormControl isInvalid={errors.mesSelected}>
              <FormLabel>Mes y año</FormLabel>
              <Controller
                control={control}
                name="mesSelected"
                rules={{ required: 'Selecciona el mes a buscar' }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <DatePicker
                    className='jmz-datepicker'
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    dateFormat='MM/yyyy'
                    showMonthYearPicker
                    placeholderText='Selecciona'
                    locale={'es'}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.mesSelected && errors.mesSelected.message}
              </FormErrorMessage>
            </FormControl>
          </Box>
          {showEmpleado &&
            <Box w='300px' >
              <MenuBarberos />
            </Box>
          }
        </Flex>
        <Flex>
          <Button colorScheme={'blackSoft'} w='100%' type='submit' isLoading={isSubmitting}>Buscar</Button>
        </Flex>
      </Flex>
    </form>
  )
}

export default FormGetRegistroMensual