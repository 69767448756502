import { Flex, Heading, Text, Badge, IconButton, Divider } from '@chakra-ui/react'
import { GoCreditCard } from 'react-icons/go'
import { GiMoneyStack } from 'react-icons/gi'
import { RiArrowLeftRightFill, RiEdit2Fill, RiDeleteBin2Fill } from 'react-icons/ri'
import React from 'react'
import { currencyFormat } from '../utils/functions'

const ItemDetalleRegistroIngresos = ({ item, handleEdit, handleChange, handleDelete, isReadOnly = false }) => {

  return (
    <Flex
      flexDir={'column'}
      my={3}
      p={5}
      borderRadius={10}
      className={'jmz-outline'}
    >

      <Flex
        justifyContent={'space-between'}
      >
        <Flex flexDir={'column'} justify={'center'}>
          <Heading size={'md'}>{item.cliente}</Heading>
          <Text>{item.fechaLog}</Text>
        </Flex>
        <Flex flexDir={'column'} align={'end'} justify={'center'}>
          <Text fontWeight={'semibold'}>{currencyFormat(item.valorPagado)}</Text>
          <Badge variant={'outline'}
            p={2}
            display={'flex'}
            alignItems={'center'}
            gap={3}
            colorScheme={item.metodoPago === 'Efectivo' ? 'blackSoft' : 'facebook'}
          >
            {item.metodoPago === 'Efectivo' ? <GiMoneyStack fontSize={18} /> : <GoCreditCard fontSize={18} />}
            {item.metodoPago}
          </Badge>
        </Flex>
      </Flex>
      {!isReadOnly &&
        <>
          <Divider my={3} colorScheme={'blackSoft'} />
          <Flex gap='3'>
            <IconButton colorScheme='blackSoft' fontSize={'2xl'} aria-label='Editar' onClick={() => handleEdit(item)} icon={<RiEdit2Fill />} />
            <IconButton colorScheme='blackSoft' fontSize={'2xl'} aria-label='Cambiar Empleado' onClick={() => handleChange(item)} icon={<RiArrowLeftRightFill />} />
            <IconButton colorScheme='red' fontSize={'2xl'} aria-label='Eliminar' onClick={() => handleDelete(item)} icon={<RiDeleteBin2Fill />} />
          </Flex>
        </>
      }
    </Flex>
  )
}

export default ItemDetalleRegistroIngresos