import React, { useState } from 'react'
import UserContext from '../services/UserContext'
import Inicio from '../pages/Inicio'
import RegistroDiario from '../pages/RegistroDiario'
import App from '../App'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import CuadreCaja from '../pages/CuadreCaja'
import Login from '../pages/Login'
import RegistroMensual from '../pages/RegistroMensual'
import Gastos from '../pages/Gastos'

const UserContainer = () => {
  const [user, setUser] = useState({})
  return (
    <UserContext.Provider value={user} >
      <Router>
        <Routes>
          <Route path="/" element={<App userAuth={(value => setUser(value))} />} >
            <Route path="/inicio" element={<Inicio />} />
            <Route path="/registro-diario" index element={<RegistroDiario />} />
            <Route path="/registro-mensual" element={<RegistroMensual />} />
            <Route path="/cuadres-de-caja" element={<CuadreCaja />} />
            <Route path="/gastos" element={<Gastos />} />
          </Route>
          <Route path="/login" index element={<Login userAuth={(value => setUser(value))} />} />
          <Route path='*' element={<div>No hay nada que buscar aqui, error 404</div>} />
        </Routes>
      </Router>
    </UserContext.Provider>
  )
}

export default UserContainer