import { Flex, Box, Text, FormControl, Select, FormErrorMessage, Button, Heading, Badge, Divider } from '@chakra-ui/react'
import { TfiAngleDoubleRight, TfiCreditCard } from 'react-icons/tfi'
import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { FiSave } from 'react-icons/fi'
import { GiMoneyStack } from 'react-icons/gi'

const FormChangeEmployee = ({ onSubmit, item, barberoActual, initData }) => {

  const [barberoNuevo, setBarberoNuevo] = useState('')
  // Declarando el useFormContext
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isSubmitSuccessful }
  } = useFormContext()

  // Hook para resetar despues de enviar
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
  }, [isSubmitSuccessful, reset])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex flexDir={'column'}>
        <Flex
          flexDir={'column'}
          my={3}
          p={5}
          borderRadius={10}
          boxShadow={'base'}
        >
          <Flex
            justifyContent={'space-between'}
          >
            <Flex flexDir={'column'} justify={'center'}>
              <Heading size={'md'}>{item.cliente}</Heading>
              <Text>{item.fechaLog}</Text>
            </Flex>
            <Flex flexDir={'column'} align={'end'} justify={'center'}>
              <Text fontWeight={'semibold'}>{item.valorPagado} €</Text>
              <Badge variant={'outline'}
                p={2}
                display={'flex'}
                alignItems={'center'}
                gap={3}
                colorScheme={item.metodoPago === 'Efectivo' ? 'blackSoft':'facebook'}
              >
                {item.metodoPago === 'Efectivo' ? <GiMoneyStack fontSize={18} /> : <TfiCreditCard fontSize={18} /> }
                {item.metodoPago}
              </Badge>
            </Flex>
          </Flex>
        </Flex>
        <Divider my={3} colorScheme={'blackSoft'} />
        <Flex mt={3} w={'100%'} justify='space-around'>
          <Text fontWeight={'semibold'} fontSize={'lg'}>{barberoActual}</Text>
          <Box><TfiAngleDoubleRight fontSize={'25px'} /></Box>
          <Text fontWeight={'semibold'} fontSize={'lg'}>{barberoNuevo}</Text>
        </Flex>
        <Flex mt={5} flexDir={'column'} gap={3}>
          <Text>Selecciona el nuevo barbero</Text>
          <FormControl id="empleado" isInvalid={errors.empleado}>
            <Select placeholder="Selecciona uno" size={'lg'} value={undefined}
              {...register('empleadoNuevo',
                { required: 'Selecciona el empleado',
                  onChange: (e) => setBarberoNuevo(e.target.value),
                //   onChange: (e) => console.log(e),
                })}
            >
              {initData.map((option, index) => (
                <option key={index} disabled={option === barberoActual ? true : false} value={option}>{option}</option>
              ))}
            </Select>
            <FormErrorMessage>
              {errors.empleado && errors.empleado.message}
            </FormErrorMessage>
          </FormControl>
          <Button w="100%" colorScheme={'blackSoft'} isLoading={isSubmitting} type={'submit'} leftIcon={<FiSave />}>GUARDAR</Button>
        </Flex>
      </Flex>
    </form>
  )
}

export default FormChangeEmployee