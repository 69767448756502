import React, { useEffect, useState } from 'react'
import {

  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  InputGroup,
  Input,
  InputRightElement,
  Textarea,
  Flex,
  Button,
  Badge,
  TableContainer,
  Table,
  Thead,
  TableCaption,
  Tr,
  Th,
  Tbody,
  Td,
  IconButton,
  Alert,
  AlertIcon,
  Box,
  Text,
  Icon,
  MenuItem,
  MenuList,
  Menu,
  MenuButton,

} from '@chakra-ui/react'

import { FaChevronDown, FaEuroSign } from 'react-icons/fa'

import { Controller, useFormContext } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import { registerLocale } from 'react-datepicker'
import es from 'date-fns/locale/es'
import { RiSave3Fill } from 'react-icons/ri'
import { currencyFormat, getIVA } from '../utils/functions'
import { MdCheck, MdClose } from 'react-icons/md'
import { ESTADO_GASTO, MEDIO_PAGO, TIPO_GASTO } from '../utils/constantes'
import moment from 'moment'
import { GiMoneyStack } from 'react-icons/gi'
import { SiVisa } from 'react-icons/si'
registerLocale('es', es)
const FormAddGastos = ({ onSubmit, listInitData }) => {

  // Declarando el useFormContext
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    reset,
    control,
    formState: { errors, isSubmitting, isSubmitSuccessful }
  } = useFormContext()

  // const handleChangeEstadoIntervalo =  (item) => {
  // }

  const [isGastoFijo, setIsGastoFijo] = useState(getValues('tipoGasto') === TIPO_GASTO.FIJO ? true : false)
  const [listDatosPeriodos, setListDatosPeriodos] = useState(getValues('tipoGasto') === TIPO_GASTO.FIJO ? getValues('datosPeriodos') : [])
  const [metodoPagoSelected, setMetodoPagoSelected] = useState('')

  const handleEstadoPeriodo = (data, estado) => {
    const index = listDatosPeriodos.findIndex(x => x.id === data.id)
    let listTemp = listDatosPeriodos.filter(x => x.id !== data.id)
    let updateData = {
      ...data,
      dpEstadoGasto: estado
    }
    listTemp.splice(index, 0, updateData)
    setListDatosPeriodos(listTemp)
    setValue('datosPeriodos', listTemp)
  }
  // Hook para resetar despues de enviar
  useEffect(() => {
    if (getValues('medioPago')) {
      setMetodoPagoSelected(getValues('medioPago'))
    }
    if (isSubmitSuccessful) {
      setIsGastoFijo(false)
      setMetodoPagoSelected('')
      reset()
    }
  }, [isSubmitSuccessful, reset])

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex flexDir={'column'} gap={4}>
          {getValues('id') &&
            <Flex justify={'center'}>
              <Badge>Id: <strong>{getValues('id')}</strong></Badge>
            </Flex>
          }
          <FormControl id="categoriaGasto" isInvalid={errors.categoriaGasto}>
            <FormLabel htmlFor='categoriaGasto' fontWeight={'semibold'}>Categoria Gasto</FormLabel>
            <Controller
              control={control}
              name="categoriaGasto"
              rules={{ required: 'Selecciona una categoria del gasto' }}
              render={({ field: { onChange, value } }) => (
                <Select placeholder="Selecciona uno" size={'lg'} value={value} onChange={onChange}
                >
                  {listInitData.categorias.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                  ))}
                </Select>
              )}
            />
            <FormErrorMessage>
              {errors.categoriaGasto && errors.categoriaGasto.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl id="tipoGasto" isInvalid={errors.tipoGasto}>
            <FormLabel htmlFor='tipoGasto' fontWeight={'semibold'}>Tipo Gasto</FormLabel>
            <Controller
              control={control}
              name="tipoGasto"
              rules={{ required: 'Selecciona el tipo de gasto' }}
              render={({ field }) => (
                <Select placeholder="Selecciona uno" size={'lg'} {...field}
                  onChange={(e) => { field.onChange(e.target.value); e.target.value === 'Fijo' ? setIsGastoFijo(true) : setIsGastoFijo(false) }}
                >
                  {listInitData.tipoGasto.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                  ))}
                </Select>
              )}
            />

            <FormErrorMessage>
              {errors.tipoGasto && errors.tipoGasto.message}
            </FormErrorMessage>
          </FormControl>
          {isGastoFijo &&
            <FormControl id="periodicidad" isInvalid={errors.periodicidad}>
              <FormLabel htmlFor='periodicidad' fontWeight={'semibold'}>Periodicidad</FormLabel>
              <Controller
                control={control}
                name="periodicidad"
                rules={{ required: 'Selecciona un valor' }}
                render={({ field }) => (
                  <Select placeholder="Selecciona uno" size={'lg'} {...field} >
                    {listInitData.periodicidad.map((option, index) => (
                      <option key={index} value={option}>{option}</option>
                    ))}
                  </Select>
                )}
              />

              <FormErrorMessage>
                {errors.periodicidad && errors.periodicidad.message}
              </FormErrorMessage>
            </FormControl>
          }
          <Flex flexDir={['column', 'row']} gap={5}>
            <FormControl id="fechaGasto" isInvalid={errors.fechaGasto}>
              <FormLabel htmlFor='fechaGasto' fontWeight={'semibold'}>Fecha Gasto</FormLabel>
              <Controller
                control={control}
                name="fechaGasto"
                rules={{ required: 'ingresa la fecha' }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <DatePicker
                    className='jmz-datepicker'
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    dateFormat='dd/MM/yyyy'
                    isClearable
                    showIcon
                    placeholderText='dd/MM/yyyy'
                    locale={'es'}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.fechaGasto && errors.fechaGasto.message}
              </FormErrorMessage>
            </FormControl>
            {isGastoFijo &&
              <FormControl id="duracionIntervalo" isInvalid={errors.duracionIntervalo}>
                <FormLabel htmlFor='duracionIntervalo' fontWeight={'semibold'}>Duración Intervalo</FormLabel>
                <Controller
                  control={control}
                  name="duracionIntervalo"
                  rules={{ required: 'ingresa un valor' }}
                  render={({ field }) => (
                    <Input size={'lg'} type='number' {...field} />
                  )}
                />
                <FormErrorMessage>
                  {errors.duracionIntervalo && errors.duracionIntervalo.message}
                </FormErrorMessage>
              </FormControl>
            }
          </Flex>
          <Flex gap={4}>
            <FormControl id="subtotal" isInvalid={errors.subtotal}>
              <FormLabel htmlFor='subtotal' fontWeight={'semibold'}>Subtotal</FormLabel>
              <Controller
                control={control}
                name="subtotal"
                rules={{ required: 'Ingresa un valor', valueAsNumber: true }}
                render={({ field }) => (
                  <InputGroup>
                    <Input size={'lg'} type='number' {...field}
                      onChange={(e) => {
                        field.onChange(Number(e.target.value))
                        setValue('IVA', getIVA(getValues('subtotal'), Number(getValues('pctjeIVA'))))
                        setValue('total', (Number(getValues('subtotal')) + Number(getValues('IVA'))))
                      }}
                    />
                    <InputRightElement>
                      <FaEuroSign color={'green'} fontSize={'25px'} />
                    </InputRightElement>
                  </InputGroup>
                )}
              />
              <FormErrorMessage>
                {errors.subtotal && errors.subtotal.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl id="pctjeIVA">
              <FormLabel htmlFor='pctjeIVA' fontWeight={'semibold'}>% IVA</FormLabel>
              <Controller
                control={control}
                name="pctjeIVA"
                defaultValue={0}
                render={({ field }) => (
                  <Select placeholder="Selecciona" size={'lg'} {...field}
                    onChange={(e) => {
                      field.onChange(e.target.value)
                      setValue('IVA', getIVA(getValues('subtotal'), Number(e.target.value)))
                      setValue('total', (Number(getValues('subtotal')) + Number(getValues('IVA'))))
                    }}
                  >
                    {listInitData.pctjesIVA.map((option, index) => (
                      <option key={index} value={option}>{option}%</option>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <FormControl id="IVA">
              <FormLabel htmlFor='IVA' fontWeight={'semibold'}>IVA</FormLabel>
              <InputGroup>
                <Input placeholder=" " size={'lg'}
                  {...register('IVA', { value: 0, valueAsNumber: true })} />
                <InputRightElement>
                  <FaEuroSign color={'green'} fontSize={'25px'} />
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </Flex>
          <FormControl id="total" isInvalid={errors.total}>
            <FormLabel htmlFor='total' fontWeight={'semibold'}>Total</FormLabel>
            <Controller
              control={control}
              name="total"
              rules={{ required: 'Ingresa un valor', valueAsNumber: true }}
              render={({ field }) => (
                <InputGroup>
                  <Input placeholder="valor total del gasto" size={'lg'} type='number' {...field} />
                  <InputRightElement>
                    <FaEuroSign color={'green'} fontSize={'25px'} />
                  </InputRightElement>
                </InputGroup>
              )}
            />
            <FormErrorMessage>
              {errors.subtotal && errors.subtotal.message}
            </FormErrorMessage>
            <FormControl id="medioPago" isInvalid={errors.medioPago}>
              <FormLabel htmlFor='medioPago' fontWeight={'semibold'}>Medio de Pago</FormLabel>
              <Menu matchWidth>
                <MenuButton w={'100%'} py={10} as={Button} rightIcon={<FaChevronDown />}>
                  {metodoPagoSelected ?
                    <Flex ma={2} w={'100%'} pa={4} gap={3} alignItems={'center'} >
                      <Box>
                        <Icon as={metodoPagoSelected === MEDIO_PAGO.EFECTIVO ? GiMoneyStack : SiVisa}
                          fontSize="6xl"
                          color={metodoPagoSelected === MEDIO_PAGO.EFECTIVO ? 'green' : 'darkBlue'} />
                      </Box>
                      <Box>
                        <Text fontWeight={'bold'}>{metodoPagoSelected}</Text>
                      </Box>
                    </Flex>
                    :
                    <>
                      Selecciona el medio de pago
                    </>
                  }
                </MenuButton>
                <MenuList  {...register('medioPago', { required: 'Selecciona el medio de pago' })} >
                  <MenuItem minH='48px' onClick={() => { setMetodoPagoSelected(MEDIO_PAGO.EFECTIVO); setValue('medioPago', MEDIO_PAGO.EFECTIVO); clearErrors('medioPago') }}>
                    <Flex ma={2} pa={4} gap={3} alignItems={'center'} >
                      <Box>
                        <Icon as={GiMoneyStack}
                          fontSize="6xl"
                          color={'green'} />
                      </Box>
                      <Box>
                        <Text fontWeight={'bold'}>{MEDIO_PAGO.EFECTIVO}</Text>
                      </Box>
                    </Flex>
                  </MenuItem>
                  <MenuItem minH='48px' onClick={() => { setMetodoPagoSelected(MEDIO_PAGO.TARJETA); setValue('medioPago', MEDIO_PAGO.TARJETA); clearErrors('medioPago') }}>
                    <Flex ma={2} pa={4} gap={3} alignItems={'center'} >
                      <Box>
                        <Icon as={SiVisa}
                          fontSize="6xl"
                          color={'darkBlue'} />
                      </Box>
                      <Box>
                        <Text fontWeight={'bold'}>{MEDIO_PAGO.TARJETA}</Text>
                      </Box>
                    </Flex>
                  </MenuItem>
                </MenuList>
              </Menu>
              <FormErrorMessage>
                {errors.medioPago && errors.medioPago.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl id="estadoGasto" isInvalid={errors.estadoGasto}>
              <FormLabel htmlFor='estadoGasto' fontWeight={'semibold'}>Estado</FormLabel>
              <Controller
                control={control}
                name="estadoGasto"
                rules={{ required: 'Selecciona el estado' }}
                render={({ field }) => (
                  <Select placeholder="Selecciona uno" size={'lg'} {...field} >
                    {listInitData.estadoGasto.map((option, index) => (
                      <option key={index} value={option}>{option}</option>
                    ))}
                  </Select>
                )}
              />

              <FormErrorMessage>
                {errors.estadoGasto && errors.estadoGasto.message}
              </FormErrorMessage>
            </FormControl>
          </FormControl>
          <FormControl id="nota">
            <FormLabel htmlFor='nota' fontWeight={'semibold'}>Nota</FormLabel>
            <Textarea
              resize={'none'}
              {...register('nota')} />
          </FormControl>
          {isGastoFijo &&
            <Flex flexDir={'column'}>
              {listDatosPeriodos.length > 0
                ?
                <>
                  <TableContainer w={'full'} borderRadius={10} className={'jmz-outline'}>
                    <Table variant='simple' >
                      <TableCaption placement='top' >Datos de periodicidad</TableCaption>
                      <Thead>
                        <Tr>
                          <Th>Fecha</Th>
                          <Th isNumeric>Valor</Th>
                          <Th>Estado</Th>
                          <Th>Acción</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {listDatosPeriodos.map((item, index) => (
                          <Tr key={index}>
                            <Td>{moment(new Date(item.dpFechaGasto.toDate())).format('DD-MM-YYYY')}</Td>
                            <Td isNumeric>{currencyFormat(item.dpSubtotal)}</Td>
                            <Td>
                              <Badge variant='subtle' colorScheme={item.dpEstadoGasto === ESTADO_GASTO.PENDIENTE ? 'orange' : 'green'}>{item.dpEstadoGasto}</Badge>
                            </Td>
                            <Td display={'flex'} gap={3}>
                              <IconButton
                                _hover={{ color: 'white', bgColor: 'red.300' }}
                                bgColor={item.dpEstadoGasto === ESTADO_GASTO.PENDIENTE ? 'red.300' : ''}
                                size={'sm'}
                                borderRadius={'full'}
                                variant='outline'
                                aria-label={ESTADO_GASTO.PENDIENTE}
                                icon={<MdClose />}
                                onClick={() => handleEstadoPeriodo(item, ESTADO_GASTO.PENDIENTE)}
                              />
                              <IconButton
                                _hover={{ color: 'white', bgColor: 'green.300' }}
                                bgColor={item.dpEstadoGasto === ESTADO_GASTO.PAGADO ? 'green.300' : ''}
                                size={'sm'}
                                borderRadius={'full'}
                                variant='outline'
                                aria-label={ESTADO_GASTO.PAGADO}
                                icon={<MdCheck />}
                                onClick={() => handleEstadoPeriodo(item, ESTADO_GASTO.PAGADO)}
                              />
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                  <Flex flexDir={'column'} w={'full'} gap={3} borderRadius={10} className={'jmz-outline'} p={3} my={3}>
                    <Flex justify={'space-between'} >
                      <Box>
                        <Badge variant='subtle' colorScheme={'orange'}>Pendiente</Badge>
                      </Box>
                      <Box>
                        <Badge variant='subtle' colorScheme={'orange'}>
                          {currencyFormat(listDatosPeriodos.reduce((acc, item) => item.dpEstadoGasto === ESTADO_GASTO.PENDIENTE ? (item.dpSubtotal + acc) : (0 + acc), 0))}
                        </Badge>
                      </Box>
                    </Flex>
                    <Flex justify={'space-between'}>
                      <Box>
                        <Badge variant='subtle' colorScheme={'green'}>Pagado</Badge>
                      </Box>
                      <Badge variant='subtle' colorScheme={'green'}>
                        {currencyFormat(listDatosPeriodos.reduce((acc, item) => item.dpEstadoGasto === ESTADO_GASTO.PAGADO ? (item.dpSubtotal + acc) : (0 + acc), 0))}
                      </Badge>
                      {/* <Box>{currencyFormat(listDatosPeriodos.reduce((acc, item) => item.dpEstadoGasto === ESTADO_GASTO.PAGADO && item.dpSubtotal + acc, 0))}</Box> */}
                    </Flex>
                    <Flex justify={'space-between'}>
                      <Box>Total Proyectado</Box>
                      <Box><strong>{currencyFormat(listDatosPeriodos.reduce((acc, item) => item.dpSubtotal + acc, 0))}</strong></Box>
                    </Flex>
                  </Flex>
                </>

                :
                <Alert status='info' rounded={'10px'}>
                  <AlertIcon />
                  No hay registros
                </Alert>
              }
            </Flex>
          }
          <Button w="100%" colorScheme={'blackSoft'} size='lg' isLoading={isSubmitting} type={'submit'} leftIcon={<RiSave3Fill />}>GUARDAR</Button>
        </Flex>
      </form>
    </>
  )
}

export default FormAddGastos