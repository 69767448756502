import React from 'react'
import { CircularProgress, Flex, Icon, Text, Heading } from '@chakra-ui/react'

const KPI = ( { title, value, icon, iconColor, children }) => {
  return (
    <Flex p={4}
      alignItems='center'
      justifyContent={'space-between'}
      bgColor={'white'}
      borderRadius={10}
      className={'jmz-outline'}
      w={{ base: '100%', md: '40%', lg: '40%', xl: '20%' , '2xl': '15%' }}>
      <Flex flexDir="column">
        <Heading as="h4" fontSize={'sm'} fontWeight="medium">{title}</Heading>
        <Text as={'div'} fontSize='2xl' fontWeight='semibold'>{value !== undefined ? value : <CircularProgress size={'48px'} isIndeterminate color={'blackAlpha.600'} />}</Text>
        <Text mt={2} opacity={'0.8'} fontSize={'sm'}>{children}</Text>
      </Flex>
      <Icon as={icon}
        fontSize="6xl"
        color={iconColor}/>
    </Flex>
  )
}

export default KPI